@charset "UTF-8";
/**
 * @site            Librairie La Liseuse
 * @author          Joana Henrique
 * @lastmodified    2024-05-21
 * @note            Configuration du projet
 */

// Couleurs
$base-color:        #1F4C7A;
$base-bg-color:     #fff;
$primary-color:     #1F4C7A ;
$secondary-color:	#4FBBB7;
$border-color:      #d3d3d3;

$gray:              #505050;

$secondary-color-light: #D8F2F2;

$warning:           #d11931;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Marcellus&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
$font-family:		"Open Sans", Verdana, sans-serif;

$shadow:		0px 8px 8px 0px rgba(0,0,0,0.2);

/**
 * Fonctions et mixins tirés de ROCSSTI
 * @note  ROCSSTI : une base CSS par Nicolas Hoffmann http://rocssti.net/
 * @note inspiré par http://www.knacss.com/
 * @note ROCSSTI est sous licence CC-BY : http://creativecommons.org/licenses/by/3.0/fr/
 */

// Ne pas modifier
$base-font: 16 !default;
$l-h:       1.5 !default;

// rythme vertical 
// valeurs possibles : 'double_top_single_bottom',  'single_top', 'single_bottom'
$vertical-rythm: 'single_bottom';
$screen-small: 1340;