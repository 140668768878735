@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-12
 * @note            Advanced (Recherche avancée)
 */

.search-advanced {
	label {
		&[for="pubDateBefore"],
		&[for="inputAdvSearchPriceMax"] {
			width: auto;
		}
	}

	input {
		&#inputAdvSearchPriceMin,
		&#inputAdvSearchPriceMax {
			width: 100px;
		}
	}

	@media screen and ( max-width: 880px ) {
		label[for="pubDateBefore"],
		label[for="inputAdvSearchPriceMax"],
		#inputAdvSearchPriceMin,
		#inputAdvSearchPriceMax {
			display: inline-block;
		}

		label[for="pubDateBefore"],
		label[for="inputAdvSearchPriceMax"] {
			margin-top: 0;
		}
	}
}