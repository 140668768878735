@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-13
 * @note            jQuery.mmenu 5.6.3
 */


/* == Base ============================= */
.mm-menu,
.mm-panels,
.mm-panels>.mm-panel {
	margin: 0;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0;
	box-sizing: border-box;
}

.mm-btn,
.mm-menu {
	box-sizing: border-box;
}

.mm-listview a,
.mm-listview a:hover,
.mm-navbar a,
.mm-navbar a:hover {
	text-decoration: none;
}

.mm-hidden {
	display: none!important;
}

.mm-menu,
.mm-panels>.mm-panel:not(.mm-hidden) {
	display: block;
}

.mm-wrapper {
	overflow-x: hidden;
	position: relative;
}

.mm-menu {
	padding: 0;
	position: absolute;
}

.mm-panels,
.mm-panels>.mm-panel {
	background: inherit;
	border-color: inherit;
	position: absolute;
}

.mm-btn,
.mm-panel.mm-highest {
	z-index: 1;
}

.mm-panels {
	overflow: hidden;
}

.mm-panel {
	-webkit-transition: -webkit-transform .4s ease;
	transition: -webkit-transform .4s ease;
	transition: transform .4s ease;
	transition: transform .4s ease, -webkit-transform .4s ease;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
}

.mm-panel.mm-opened {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
	-webkit-transform: translate3d(-30%, 0, 0);
	transform: translate3d(-30%, 0, 0);
}

.mm-panels>.mm-panel {
	-webkit-overflow-scrolling: touch;
	overflow: scroll;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 20px;
	padding: 0;
}

.mm-listview .mm-divider,
.mm-listview>li>a,
.mm-listview>li>span,
.mm-navbar .mm-title {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.mm-panels>.mm-panel.mm-hasnavbar {
	padding-top: 40px;
}

.mm-panels>.mm-panel:after,
.mm-panels>.mm-panel:before {
	content: '';
	display: block;
	height: 20px;
}

.mm-vertical .mm-panel {
	-webkit-transform: none!important;
	-ms-transform: none!important;
	transform: none!important;
}

.mm-listview .mm-vertical .mm-panel,
.mm-vertical .mm-listview .mm-panel {
	display: none;
	// padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview>li:last-child:after,
.mm-vertical .mm-listview .mm-panel .mm-listview>li:last-child:after {
	border-color: transparent;
}

.mm-vertical li.mm-opened>.mm-panel,
li.mm-vertical.mm-opened>.mm-panel {
	display: block;
}

.mm-listview>li.mm-vertical>.mm-next,
.mm-vertical .mm-listview>li>.mm-next {
	height: 40px;
	bottom: auto;
}

.mm-listview>li.mm-vertical>.mm-next:after,
.mm-vertical .mm-listview>li>.mm-next:after {
	top: 16px;
	bottom: auto;
}

.mm-listview>li.mm-vertical.mm-opened>.mm-next:after,
.mm-vertical .mm-listview>li.mm-opened>.mm-next:after {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	right: 19px;
}

.mm-btn {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 0;
}

.mm-clear:after,
.mm-clear:before,
.mm-close:after,
.mm-close:before {
	content: '';
	border: 2px solid transparent;
	display: block;
	width: 5px;
	height: 5px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.mm-clear:before,
.mm-close:before {
	border-right: none;
	border-bottom: none;
	right: 18px;
}

.mm-clear:after,
.mm-close:after {
	border-left: none;
	border-top: none;
	right: 25px;
}

.mm-arrow:after,
.mm-next:after,
.mm-prev:before {
	content: '';
	border: 2px solid transparent;
	display: block;
	width: 8px;
	height: 8px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.mm-prev:before {
	border-right: none;
	border-bottom: none;
	left: 23px;
}

.mm-arrow:after,
.mm-next:after {
	border-top: none;
	border-left: none;
	right: 23px;
}

.mm-navbar {
	// border-bottom: 1px solid;
	// border-color: inherit;
	border-bottom: 0;
	// text-align: center;
	line-height: 20px;
	height: 40px;
	// padding: 0 40px;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	text-transform: uppercase;
	font-weight: bold;
}

.mm-navbar>* {
	display: block;
	// padding: 10px 0;
	padding: 10px 10px 10px 20px;
}

.mm-navbar > .mm-btn + .mm-title {
	padding-left: 50px;
}

.mm-navbar .mm-btn:first-child {
	padding-left: 20px;
	left: 0;
}

.mm-navbar .mm-btn:last-child {
	text-align: right;
	padding-right: 20px;
	right: 0;
}

.mm-panel .mm-navbar {
	display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
	display: block;
}

.mm-listview,
.mm-listview>li {
	list-style: none;
	display: block;
	padding: 0;
	margin: 0;
}

.mm-listview {
	font: inherit;
	font-size: 14px;
	line-height: 20px;
}

.mm-listview>li {
	position: relative;
}

.mm-listview>li,
.mm-listview>li .mm-next,
.mm-listview>li .mm-next:before,
.mm-listview>li:after {
	// border-color: inherit;
	border-color: rgba( $base-color, .25 );
}

.mm-listview>li>a,
.mm-listview>li>span {
	color: inherit;
	display: block;
	padding: 10px 10px 10px 20px;
	margin: 0;
}

.mm-listview>li>a.mm-arrow,
.mm-listview>li>span.mm-arrow {
	padding-right: 50px;
}

.mm-listview>li:not(.mm-divider):after {
	content: '';
	border-bottom-width: 1px;
	border-bottom-style: solid;
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
	// left: 20px;
	left: 0;
}

.mm-listview .mm-next {
	background: rgba(3, 2, 1, 0);
	width: 50px;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
}

.mm-listview .mm-next:before {
	content: '';
	border-left-width: 1px;
	border-left-style: solid;
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
}

.mm-listview .mm-next+a,
.mm-listview .mm-next+span {
	margin-right: 50px;
}

.mm-listview .mm-next.mm-fullsubopen {
	width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
	border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen+a,
.mm-listview .mm-next.mm-fullsubopen+span {
	padding-right: 50px;
	margin-right: 0;
}

.mm-panels>.mm-panel>.mm-listview {
	// margin: 20px -20px;
}

.mm-panels>.mm-panel>.mm-listview:first-child,
.mm-panels>.mm-panel>.mm-navbar+.mm-listview {
	margin-top: -20px;
}

.mm-listview .mm-inset {
	list-style: disc inside;
	padding: 0 10px 15px 40px;
	margin: 0;
}

.mm-listview .mm-inset>li {
	padding: 5px 0;
}

.mm-listview .mm-divider {
	font-size: 10px;
	text-transform: uppercase;
	text-indent: 20px;
	line-height: 25px;
}

.mm-listview .mm-spacer {
	padding-top: 40px;
}

.mm-listview .mm-spacer>.mm-next {
	top: 40px;
}

.mm-listview .mm-spacer.mm-divider {
	padding-top: 25px;
}

.mm-menu {
	background: $base-bg-color;
	border-color: rgba(0, 0, 0, .1);
	/* Ouverture à droite */
	// border-left: 2px solid $base-color;
	/* Ouverture à gauche */
	border-right: 2px solid $base-color;
	color: $base-color;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar>* {
	// color: rgba(0, 0, 0, .3);
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	// border-color: rgba(0, 0, 0, .3);
	border-color: currentColor;
}

.mm-menu .mm-listview {
	border-color: rgba(0, 0, 0, .1);
}

.mm-menu .mm-listview>li .mm-arrow:after,
.mm-menu .mm-listview>li .mm-next:after {
	// border-color: rgba(0, 0, 0, .3);
	border-color: currentColor;
}

.mm-menu .mm-listview>li a:not(.mm-next) {
	-webkit-tap-highlight-color: rgba(255, 255, 255, .5);
	tap-highlight-color: rgba(255, 255, 255, .5);
}

.mm-menu .mm-listview>li.mm-selected>a:not(.mm-next),
.mm-menu .mm-listview>li.mm-selected>span {
	background: rgba(255, 255, 255, .5);
}

.mm-menu .mm-divider,
.mm-menu .mm-listview>li.mm-opened.mm-vertical>.mm-panel,
.mm-menu .mm-listview>li.mm-opened.mm-vertical>a.mm-next,
.mm-menu.mm-vertical .mm-listview>li.mm-opened>.mm-panel,
.mm-menu.mm-vertical .mm-listview>li.mm-opened>a.mm-next {
	background: rgba(0, 0, 0, .05);
}

.mm-page {
	box-sizing: border-box;
	position: relative;
}

.mm-slideout {
	-webkit-transition: -webkit-transform .4s ease;
	transition: -webkit-transform .4s ease;
	transition: transform .4s ease;
	transition: transform .4s ease, -webkit-transform .4s ease;
	z-index: 1;
}

html.mm-opened {
	overflow-x: hidden;
	position: relative;
}

html.mm-blocking,
html.mm-blocking body {
	overflow: hidden;
}

html.mm-background .mm-page {
	background: inherit;
}

#mm-blocker {
	background: rgba(3, 2, 1, 0);
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
}

html.mm-blocking #mm-blocker {
	display: block;
}

.mm-menu.mm-offcanvas {
	z-index: 0;
	display: none;
	position: fixed;
}

.mm-menu.mm-offcanvas.mm-current {
	display: block;
}

.mm-menu {
	width: 80%;
	min-width: 140px;
	max-width: 440px;
}

/* -- Ouverture à droite ou à gauche ----------------------------- */
/* Ouverture à gauche */
html.mm-opening .mm-slideout {
	-webkit-transform: translate3d(80%, 0, 0);
	transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
	html.mm-opening .mm-slideout {
		-webkit-transform: translate3d(140px, 0, 0);
		transform: translate3d(140px, 0, 0);
	}
}

@media all and (min-width: 550px) {
	html.mm-opening .mm-slideout {
		-webkit-transform: translate3d(440px, 0, 0);
		transform: translate3d(440px, 0, 0);
	}
}

/* Ouverture à droite */
// .mm-menu {
// 	left: auto;
// 	right: 0;
// }

// html.mm-opening .mm-slideout {
// 	-webkit-transform: translate3d(-80%, 0, 0);
// 	transform: translate3d(-80%, 0, 0);
// }

// @media all and (max-width: 175px) {
// 	html.mm-opening .mm-slideout {
// 		-webkit-transform: translate3d(-140px, 0, 0);
// 		transform: translate3d(-140px, 0, 0);
// 	}
// }

// @media all and (min-width: 550px) {
// 	html.mm-opening .mm-slideout {
// 		-webkit-transform: translate3d(-440px, 0, 0);
// 		transform: translate3d(-440px, 0, 0);
// 	}
// }


/* == Extension ============================= */
/* -- Navbars ----------------------------- */
.mm-navbar-bottom,
.mm-navbar-top~.mm-navbar-top {
	border-bottom: none;
}

.mm-menu>.mm-navbar {
	background: inherit;
	padding: 0;
	z-index: 3;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.mm-navbar-bottom {
	border-top-width: 1px;
	border-top-style: solid;
	top: auto;
	bottom: 0;
}

.mm-navbar-bottom~.mm-navbar-bottom {
	border-top: none;
}

.mm-navbar.mm-hasbtns {
	padding: 0 40px;
}

.mm-navbar[class*=mm-navbar-content-]>* {
	box-sizing: border-box;
	display: block;
	float: left;
}

.mm-navbar .mm-breadcrumbs {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	text-align: left;
	padding: 0 0 0 17px;
}

.mm-navbar .mm-breadcrumbs>* {
	display: inline-block;
	padding: 10px 3px;
}

.mm-navbar .mm-breadcrumbs>a {
	text-decoration: underline;
}

.mm-navbar.mm-hasbtns .mm-breadcrumbs {
	margin-left: -40px;
}

.mm-navbar.mm-hasbtns .mm-btn:not(.mm-hidden)+.mm-breadcrumbs {
	margin-left: 0;
	padding-left: 0;
}

.mm-navbar-top-1 {
	top: 0;
}

.mm-hasnavbar-top-1 .mm-panels,
.mm-navbar-top-2 {
	top: 40px;
}

.mm-hasnavbar-top-2 .mm-panels,
.mm-navbar-top-3 {
	top: 80px;
}

.mm-hasnavbar-top-3 .mm-panels,
.mm-navbar-top-4 {
	top: 120px;
}

.mm-hasnavbar-top-4 .mm-panels	{ top: 160px; }
.mm-navbar-bottom-1				{ bottom: 0; }

.mm-hasnavbar-bottom-1 .mm-panels,
.mm-navbar-bottom-2 {
	bottom: 40px;
}

.mm-hasnavbar-bottom-2 .mm-panels,
.mm-navbar-bottom-3 {
	bottom: 80px;
}

.mm-hasnavbar-bottom-3 .mm-panels,
.mm-navbar-bottom-4 {
	bottom: 120px;
}

.mm-hasnavbar-bottom-4 .mm-panels	{ bottom: 160px; }
.mm-navbar-size-2					{ height: 80px; }
.mm-navbar-size-3					{ height: 120px; }
.mm-navbar-size-4					{ height: 160px; }
// .mm-navbar-content-2>*				{ width: 50%; }
// .mm-navbar-content-3>*				{ width: 33.33%; }
// .mm-navbar-content-4>*				{ width: 25%; }
// .mm-navbar-content-5>*				{ width: 20%; }
// .mm-navbar-content-6>*				{ width: 16.67%; }