@charset "UTF-8";
/**
 * @lastmodified 2015-11-27
 * @author       Luc Poupard
 * @note         Class utilitaires
 */


/* ----------------------------- */
/* Sommaire */
/* ----------------------------- */
/*
    == Alignements
    == Largeurs
    == Marges
    == Visibilité
*/


/* == @section Alignements ==================== */
.center { text-align: center; }
.left   { text-align: left; }
.right  { text-align: right; }

.fleft  { float: left; }
.fright { float: right; }
.fnone  { float: none; }

.clear  { clear: both; }

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

@mixin clearfix {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* == @section Largeurs ==================== */
.w10  { width: 10%; }
.w20  { width: 20%; }
.w30  { width: 30%; }
.w40  { width: 40%; }
.w50  { width: 50%; }
.w60  { width: 60%; }
.w70  { width: 70%; }
.w80  { width: 80%; }
.w90  { width: 90%; }
.w100 { width: 100%; }

.w20px  { width: 20px; }
.w30px  { width: 30px; }
.w40px  { width: 40px; }
.w50px  { width: 50px; }
.w60px  { width: 60px; }
.w70px  { width: 70px; }
.w80px  { width: 80px; }
.w90px  { width: 90px; }
.w100px { width: 100px; }
.w120px { width: 120px; }
.w150px { width: 150px; }
.w180px { width: 180px; }
.w200px { width: 200px; }


/* == @section Marges  ==================== */

/* == @section Visibilité  ==================== */
.display-in     { display: inline; }
.display-inbl   { display: inline-block; }
.display-bl     { display: block; }
.display-no     { display: none; }

/* Pour masquer un élément visuellement et qu'il reste accessible aux moteurs de recherche et technologies d'assistance */
.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}