@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-11-09
 * @note            Layout
 */


/* ----------------------------- */
/* Sommaire */
/* ----------------------------- */
/*
	== Global
	  -- Titles
	  -- Links
	  -- Lists
	== Layout
	== Header
	== Navigations
	  -- Skiplinks
	  -- Main menu
	  -- Categories
	  -- Breadcrumb
	  -- Mobile
	== Search
	== Content
	  -- Sidebar
	  -- Main
	== Account
	  -- Header
	  -- Login
	== Basket
	== Footer
*/

/* == @section Global ==================== */
* {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}

html {
	/* set base font-size to equiv "10px", which is adapted to em/rem unit */
	font-size: 62.5%;
	/* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
	/* thanks to @guardian, @victorbritopro and @eQRoeil */
	font-size: calc(1em * .625);
}

body {
	background-color: $base-bg-color;
	color: $base-color;
	font: {
		family: $font-family;
		size: 1.4em; // Equiv 14px
	}
}

img {
	max-width: 100%;
	height: auto;
}

address {
	font-style: normal;
}


/* -- @subsection Title ---------- */
h1,
.h1 {
	@include flow-hr(19);
}

h2,
.h2 {
	@include flow-hr(17);
}

h3,
.h3 {
	@include flow-hr(15);
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {	
	@include flow-hr(14);
}


/* -- @subsection Links ---------- */
a {
	color: $base-color;
	text-decoration: underline;
}

a:hover,
a:focus {
	text-decoration: none;
}

main,
.windowLogin,
.t-calendar {
	a {
		color: $content-color;
	}
}


/* -- @subsection Lists ---------- */
ul.horizontal-nav {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		vertical-align: middle;
	}
}


/* == @section Layout ==================== */
#page {
	position: relative;
	margin: auto;
	max-width: $page-width+px;
}

/* Mobile */
@media screen and ( max-width: $screen-small+px ) {
	// On masque les menus principaux
	#nav,
	#categories {
		display: none;
	}

	#wrap-search-content,
	#content {
		@include flexbox();
		@include flexbox-direction();
	}

	#search,
	#content,
	#sidebar,
	#main {
		@include flex(0);
		width: 100%;
	}

	#search		{ @include order(2); }
	#content	{ @include order(1); }

	#sidebar	{ @include order(2); }
	#main		{ @include order(1); }
}

/* Desktop */
@media screen and ( min-width: ( $screen-small + 1px ) ) {
	// On masque les menus mobiles
	#menu-mobile,
	#skiplinks {
		display: none;
	}

	#content {
		display: table;
		width: 100%;
		table-layout: fixed;

		> div,
		> main {
			display: table-cell;
			vertical-align: top;
		}
	}

	#sidebar {
		width: $sidebar-width+px;
	}

	#user-info {
		position: absolute;
		top: 1em;
		right: 0;
	}

	.level1:not(.selectedCategorie) > ul {
		display: none;
	}
}


/* == @section Header ==================== */
#header {
	padding-bottom: .5em;

	@media screen and ( max-width: $screen-small+px ) {
		padding-top: .5em;
		text-align: center;

		img {
			max-height: 100px;
			width: auto;
		}
	}
}


/* == @section Navigations ==================== */
/* -- @subsection Skiplinks ---------- */
#skiplinks {
	padding: .5em;
	background-color: $skiplink-bg-color;
	color: $skiplink-color;
	text-align: center;

	ul.horizontal-nav {
		li {
			margin: 0 .5em;
			width: 18%;
		}
	}

	a,
	a:visited,
	a:active {
		text-decoration: none;
	}

	a:hover,
	a:focus {
		text-decoration: underline;
	}
}


/* -- @subsection Main menu ---------- */
#nav {
	background-color: $nav-bg-color;
	color: $nav-color;

	ul.horizontal-nav {
		display: table;
		width: 100%;

		li {
			display: table-cell;
			text-align: center;

			&.nav-social {
				text-align: right;

				img {
					vertical-align: sub;
				}
			}
		}
	}

	a,
	a:visited,
	a:active {
		text-decoration: none;
	}
}


/* -- @subsection Categories ---------- */
#categories {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			&.cat-title {
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}

	a,
	.cat-title > span {
		display: block;
		padding: .3em .75em;
	}

	.level2 a {
		padding-left: 1.5em;
	}

	a,
	a:visited,
	a:active {
		text-decoration: none;
	}
}


/* -- @subsection Breadcrumb ---------- */
ul.breadcrumb {
	margin-bottom: 1em;
	font-size: small;

	li:not(:last-child):after {
		content: ">";
		margin: 0 .4em 0 .8em;
	}

	a {
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}


/* -- @subsection Mobile ---------- */
.sprite-basket {
	&:after {
		display: inline-block;
		content: attr(data-count);
		position: absolute;
		bottom: 0;
		right: 0;
		width: 18px;
		height: 18px;
		line-height: 18px;
		background: $gradient-add-top-hover;
		background: -moz-linear-gradient(top, $gradient-add-top-hover 0%, $gradient-add-bottom-hover 100%);
		background: -webkit-linear-gradient(top,  $gradient-add-top-hover 0%,$gradient-add-bottom-hover 100%);
		background: linear-gradient(to bottom,  $gradient-add-top-hover 0%,$gradient-add-bottom-hover 100%);
		color: $white;
		text-indent: 0;
		font-size: smaller;
		font-weight: bold;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
	}

	&[data-count="0"]:after {
		display: none;
	}
}

.sprite-user.user-connected {
	&:after {
		display: inline-block;
		content: "";
		position: absolute;
		bottom: 0;
		right: 0;
		width: 18px;
		height: 18px;
		line-height: 18px;
		background: #00FF00;
		background: -moz-linear-gradient(top, #00FF00 0%, #00C000 100%);
		background: -webkit-linear-gradient(top,  #00FF00 0%,#00C000 100%);
		background: linear-gradient(to bottom,  #00FF00 0%,#00C000 100%);
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
	}
}

/* == @section Search ==================== */
#search {
	padding: .9em .75em .75em .75em;

	.search-title {
		display: block;
		margin-bottom: .5em;
		font: {
			size: 1.1em;
			weight: bold;
		}
		text-transform: uppercase;
	}

	.search-advanced {
		font-size: small;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	@media screen and ( max-width: $screen-small+px ) {
		select {
			width: 100%;
		}

		.search-input,
		.search-advanced {
			display: block;
			margin-top: 1em;
		}
	}

	@media screen and ( min-width: ( $screen-small + 1 )+px ) {
		.search-title {
			display: none;
		}

		> form {
			display: inline-block;
		}

		select {
			width: 220px;
		}

		.search-input,
		.search-advanced {
			margin-left: 20px;
			display: inline-block;
		}

		.search-input {
			input[type="search"] {
				width: 300px;
			}
		}
	}

	@media screen and ( min-width: 971px ) {
		.search-input {
			input[type="search"] {
				width: 440px;
			}
		}
	}
}


/* == @section Content ==================== */
/* -- @subsection Sidebar ---------- */
.sidebar {
	&-contact {
		ul {
			padding: 0;
			list-style: none;
		}
		
		a,
		a:visited,
		a:active {
			color: $primary-color;
			text-decoration: none;
		}

		a:hover,
		a:focus {
			text-decoration: underline;
		}

		@media screen and ( max-width: $screen-small+px ) {
			ul {
				li {
					display: inline-block;
					margin: 1em 1.5em 0 0;
					vertical-align: middle;
				}
			}
		}

		@media screen and ( min-width: 490px ) and ( max-width: $screen-small+px ) {
			address,
			p {
				display: inline-block;
				margin: 0;
				width: 48%;
				vertical-align: bottom;
			}
		}
	}
}


/* -- @subsection Main ---------- */
.windowLogin,
#main {
	background-color: $content-bg-color;
	color: $content-color;
}

#main {
	padding: 1em;
}


/* == @section Account ==================== */
/* -- @subsection Header ---------- */
#account {	
	ul.horizontal-nav {
		font-size: small;

		li {
			margin: 0 .25em;

			&:not(:last-child):after {
				content: " | ";
				margin-left: .5em;
			}

			&:last-child {
				margin-right: 0;
			}

		}
	}

	a,
	a:visited,
	a:active {
		text-decoration: none;
	}

	a:hover,
	a:focus {
		text-decoration: underline;
	}

	.account-form {
		margin-top: .5em;
	}

	.error-message {
		color: lighten( $error, 25% );
	}

	@media screen and ( max-width: $screen-small+px ) {
		padding: 1em 0 1.5em 0;
		text-align: center;
	}

	@media screen and ( min-width: ( $screen-small + 1 )+px ) {
		text-align: right;
	}
}

@media screen and ( max-width: $screen-small+px ) {
	.js #account {
		display: none;
	}
}


/* -- @subsection Modale ---------- */
.windowLogin {
	form > div {
		margin-bottom: .5em;
	}
}


/* -- @subsection ---------- */
#account,
.windowLogin {
	input[type="text"],
	input[type="password"] {
		width: 115px;
		font-size: small;
	}

	input[type="submit"] {
		width: 15px;
		height: 24px;
		background: {
			color: transparent;
			image: url('#{$img-url-old}/btnSubmitLogin.png');
			repeat: no-repeat;
			position: 0 4px;
		}
		border: none;
		text-indent: 15px;
		overflow: hidden;
	}
}


/* == @section Basket ==================== */
#basket {
	display: block;
	padding: 0 .5em;
	background-color: $basket-bg-color;
	text: {
		decoration: none;
		align: center;
	}

	.basket-name {
		font-weight: bold;
	}

	&:hover,
	&:focus {
		background-color: $basket-bg-color-hover;
	}

	@media screen and ( max-width: $screen-small+px ) {
		display: none;
	}

	@media screen and ( min-width: ( $screen-small + 1 )+px ) {
		float: right;
		margin-top: 1.5em;
	}
}


/* == @section Footer ==================== */
#footer {
	color: $grey-medium;
	font-size: small;

	@media screen and ( max-width: $screen-small+px )			{ text-align: center; }
	@media screen and ( min-width: ( $screen-small + 1 )+px )	{ text-align: right; }

	ul.horizontal-nav {
		li {
			margin: 0 .5em;
		}
	}

	a,
	a:visited,
	a:active {
		color: $grey-medium;
		text-decoration: none;
	}

	a:hover,
	a:focus {
		text-decoration: underline;
	}

	.footer-copyright {
		color: $grey-light;

		a {
			color: $grey-light;
		}
	}
}