@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-11-06
 * @note            Telerik - Common
 */

.t-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	text-decoration: none;
	font-size: 100%;
	list-style: none;
}

.t-floatwrap:after {
	content: "";
	display: block;
	clear: both;
	visibility: hidden;
	height: 0;
	overflow: hidden;
}

.t-floatwrap {
	display: inline-block;
}

.t-floatwrap {
	display: block;
}

.t-widget,
.t-widget .t-input,
.t-widget .text-box,
.t-button {
	font-size: 100%;
}

.t-widget,
.t-input {
	// border-width:1px;
	// border-style:solid;
}

.t-checkbox .t-input {
	border-width: 0;
}

.t-link {
	cursor: pointer;
	outline: none;
}

.t-grid-header span.t-link {
	cursor: default;
}

html body .t-button {
	text-decoration: none;
}

.t-button {
	display: inline-block;
	margin: 0;
	padding: 2px 6px;
	border-width: 1px;
	border-style: solid;
	font-family: inherit;
	font-size: 100%;
	line-height: 1.435;
	text-align: center;
	cursor: pointer;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

*+html .t-button {
	display: inline;
	zoom: 1;
}

* html .t-button {
	display: inline;
	zoom: 1;
}

*+html .t-button {
	overflow: visible;
	margin-right: 4px;
}

* html .t-button {
	overflow: visible;
	margin-right: 4px;
}

*+html a.t-button {
	line-height: 1.6;
	padding-left: 7px;
	padding-right: 7px;
}

* html a.t-button {
	line-height: 1.6;
	padding-left: 7px;
	padding-right: 7px;
}

.t-button-icontext {
	line-height: 1.453;
}

button.t-button::-moz-focus-inner,
input.t-button::-moz-focus-inner {
	padding: 1px 0;
	border: 0;
}

.t-button-expand {
	margin-left: 0!important;
	margin-right: 0!important;
}

a.t-button-expand {
	display: block;
}

button.t-button-expand {
	width: 100%;
}

body .t-button-icon {
	padding-left: 4px;
	padding-right: 4px;
}

*+html a.t-button-icon {
	padding-left: 5px;
	padding-right: 5px;
}

* html a.t-button-icon {
	padding-left: 5px;
	padding-right: 5px;
}

.t-button-icontext {
	padding-right: 8px;
	overflow: visible;
}

.t-button-icontext .t-icon {
	margin: 0 3px 0 -3px;
	vertical-align: top;
}

*+html .t-button-icontext .t-icon {
	margin-right: 4px;
}

* html .t-button-icontext .t-icon {
	margin-right: 4px;
}

html body .t-button-bare {
	background: none!important;
	border-width: 0;
}

.t-icon,
.t-editor-button .t-tool-icon {
	background-color: transparent;
	background-repeat: no-repeat;
}

.t-header .t-link {
	text-decoration: none;
}

.t-state-disabled,
.t-state-disabled .t-link {
	cursor: default;
	outline: none;
}

.t-state-error,
.t-widget.input-validation-error,
.t-widget .input-validation-error {
	border-style: ridge;
}

.t-icon,
.t-sprite,
.t-editor-button .t-tool-icon {
	display: inline-block;
	width: 16px;
	height: 16px;
	overflow: hidden;
	font-size: 0;
	line-height: 0;
	text-indent: -3333px;
	text-align: center;
	vertical-align: middle;
}

* html .t-icon,
* html .t-sprite,
* html .t-editor-button .t-tool-icon {
	display: inline;
	zoom: 1;
}

* html .t-icon,
.t-sprite {
	text-indent: 0;
}

*+html .t-icon,
.t-sprite {
	text-indent: 0;
}

.t-image {
	border: 0;
}

.t-state-empty {
	font-style: italic;
}

.t-popup,
.t-menu .t-group {
	-moz-box-shadow: 2px 2px 2px #aaa;
	-webkit-box-shadow: 2px 2px 2px #aaa;
	box-shadow: 2px 2px 2px #aaa;
}

.t-expand {
	background-position: 0 -160px;
}

.t-collapse {
	background-position: 0 -176px;
}

.t-rtl .t-expand {
	background-position: -64px -160px;
}

.t-plus,
.t-plus-disabled {
	background-position: 0 -256px;
}

.t-minus,
.t-minus-disabled {
	background-position: -16px -256px;
}

.t-rtl .t-plus,
.t-rtl .t-plus-disabled {
	background-position: 0 -272px;
}

.t-rtl .t-minus,
.t-rtl .t-minus-disabled {
	background-position: -16px -272px;
}

.t-arrow-up {
	background-position: -16px -160px;
}

.t-arrow-down {
	background-position: -16px -176px;
}

.t-state-disabled .t-arrow-up {
	background-position: -32px -160px;
}

.t-state-disabled .t-arrow-down {
	background-position: -32px -176px;
}

.t-state-hover .t-arrow-up {
	background-position: -48px -160px;
}

.t-state-hover .t-arrow-down {
	background-position: -48px -176px;
}

.t-numerictextbox .t-arrow-up {
	background-position: -16px -162px;
}

.t-numerictextbox .t-arrow-down {
	background-position: -16px -178px;
}

.t-arrow-up-small {
	background-position: 0 -304px;
}

.t-arrow-down-small {
	background-position: -16px -304px;
}

.t-arrow-first {
	background-position: 0 -192px;
}

.t-arrow-prev {
	background-position: -16px -192px;
}

.t-arrow-next {
	background-position: -32px -192px;
}

.t-arrow-last {
	background-position: -48px -192px;
}

.t-state-disabled .t-arrow-first {
	background-position: 0 -208px;
}

.t-state-disabled .t-arrow-prev {
	background-position: -16px -208px;
}

.t-state-disabled .t-arrow-next {
	background-position: -32px -208px;
}

.t-state-disabled .t-arrow-last {
	background-position: -48px -208px;
}

.t-state-hover .t-arrow-first {
	background-position: 0 -224px;
}

.t-state-hover .t-arrow-prev {
	background-position: -16px -224px;
}

.t-state-hover .t-arrow-next {
	background-position: -32px -224px;
}

.t-state-hover .t-arrow-last {
	background-position: -48px -224px;
}

.t-filter {
	background-position: 0 -240px;
}

.t-clear-filter {
	background-position: -16px -240px;
}

.t-refresh {
	background-position: -32px -240px;
}

.t-icon-calendar {
	background-position: -48px -240px;
}

.t-icon-clock {
	background-position: -64px -336px;
}

.t-edit {
	background-position: 0 -336px;
}

.t-delete {
	background-position: -16px -336px;
}

.t-insert,
.t-update {
	background-position: -32px -336px;
}

.t-cancel {
	background-position: -48px -336px;
}

.t-group-delete {
	background-position: 0 -288px;
}

.t-maximize {
	background-position: -16px -288px;
}

.t-restore {
	background-position: -32px -288px;
}

.t-close {
	background-position: -64px -288px;
}

.t-insert-top {
	background-position: 0 -320px;
}

.t-insert-middle {
	background-position: -16px -320px;
}

.t-insert-bottom {
	background-position: -32px -320px;
}

.t-add {
	background-position: -48px -320px;
}

.t-denied {
	background-position: -64px -320px;
}

.t-resize-se {
	background-position: -64px -272px;
}

.t-autocomplete,
.t-input {
	padding: 2px 1px 3px;
}

div.t-window {
	display: inline-block;
	*display: inline;
	zoom: 1;
	z-index: 10001;
	position: absolute;
	border-width: 0;
	border-width: 5px\9;
	// -moz-border-radius: 5px;
	// -webkit-border-radius: 5px;
	// border-radius: 5px;
	-moz-box-shadow: 0 0 5px 2px rgba( $grey-dark, .75 );
	-webkit-box-shadow: 0 0 5px 2px rgba( $grey-dark, .75 );
	box-shadow: 0 0 5px 2px rgba( $grey-dark, .75 );
	max-width: 94%;
}

:root .t-window {
	border-width: 0;
}

.t-window-titlebar {
	padding: .4em 0;
	// font-size: 1.2em;
	// line-height: 1.2em;
	white-space: nowrap;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	// -moz-border-radius-topleft: 5px;
	// -moz-border-radius-topright: 5px;
	// -webkit-border-top-left-radius: 5px;
	// -webkit-border-top-right-radius: 5px;
	// border-top-left-radius: 5px;
	// border-top-right-radius: 5px;
}

.t-window-title {
	cursor: default;
	position: absolute;
	text-overflow: ellipsis;
	overflow: hidden;
	left: .5em;
	right: .5em;
}

.t-window-title .t-image {
	margin: 0 5px 0 0;
	vertical-align: middle;
}

div.t-window-content {
	padding: .4em .5em;
	overflow: auto;
	position: relative;
	// -moz-border-radius-bottomleft: 4px;
	// -moz-border-radius-bottomright: 4px;
	// -webkit-border-bottom-left-radius: 4px;
	// -webkit-border-bottom-right-radius: 4px;
	// border-bottom-left-radius: 4px;
	// border-bottom-right-radius: 4px;
	max-width: 100%;
}

.t-window-titlebar .t-window-actions {
	position: absolute;
	right: .5em;
	top: 0;
	padding-top: .3em;
	white-space: nowrap;
}

.t-window-titlebar .t-link {
	width: 16px;
	height: 16px;
	// padding: 2px;
	text-decoration: none;
	vertical-align: middle;
	display: inline-block;
	*display: inline;
	zoom: 1;
	opacity: .7;
	filter: alpha(opacity=70);
}

.t-window-titlebar .t-state-hover {
	// border-width: 1px;
	// border-style: solid;
	// padding: 1px;
	// -moz-border-radius: 5px;
	// -webkit-border-radius: 5px;
	// border-radius: 5px;
	opacity: 1;
	filter: alpha(opacity=100);
}

.t-window-actions .t-icon {
	margin: 0;
	vertical-align: top;
}

.t-window .t-resize-handle {
	position: absolute;
	z-index: 1;
	background-color: #fff;
	opacity: 0;
	filter: alpha(opacity=0);
	zoom: 1;
	line-height: 6px;
	font-size: 0;
}

.t-resize-n {
	top: -3px;
	left: 0;
	width: 100%;
	height: 6px;
	cursor: n-resize;
}

.t-resize-e {
	top: 0;
	right: -3px;
	width: 6px;
	height: 100%;
	cursor: e-resize;
}

.t-resize-s {
	bottom: -3px;
	left: 0;
	width: 100%;
	height: 6px;
	cursor: s-resize;
}

.t-resize-w {
	top: 0;
	left: -3px;
	width: 6px;
	height: 100%;
	cursor: w-resize;
}

.t-resize-se {
	bottom: -3px;
	right: -3px;
	width: 16px;
	height: 16px;
	cursor: se-resize;
}

.t-resize-sw {
	bottom: -3px;
	left: -3px;
	width: 6px;
	height: 6px;
	cursor: sw-resize;
}

.t-resize-ne {
	top: -3px;
	right: -3px;
	width: 6px;
	height: 6px;
	cursor: ne-resize;
}

.t-resize-nw {
	top: -3px;
	left: -3px;
	width: 6px;
	height: 6px;
	cursor: nw-resize;
}

.t-window .t-resize-se {
	opacity: 1;
	filter: alpha(opacity=100);
	background-color: transparent;
}

.t-overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #000;
	filter: alpha(opacity=50);
	opacity: .5;
	z-index: 10001;
}

.t-window .t-overlay {
	background-color: #fff;
	opacity: 0;
	filter: alpha(opacity=0);
	position: absolute;
	width: 100%;
	height: 100%;
}

.t-window .t-widget {
	z-index: 10002;
}

.t-tabstrip {
	margin: 0;
	padding: 0;
	zoom: 1;
}

.t-tabstrip .t-tabstrip-items {
	padding: 0 .3em;
}

.t-tabstrip-items .t-item,
.t-panelbar .t-tabstrip-items .t-item {
	list-style-type: none;
	vertical-align: top;
	display: inline-block;
	*display: inline;
	zoom: 1;
	border-width: 1px 1px 0;
	border-style: solid;
	margin: .1em .3em 0 0;
	padding: 0;
	position: relative;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-topright: 5px;
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.t-tabstrip-items .t-state-active,
.t-panelbar .t-tabstrip-items .t-state-active {
	padding-bottom: 1px;
	margin-bottom: -1px;
}

.t-tabstrip-items .t-link,
.t-panelbar .t-tabstrip-items .t-link {
	padding: .3em .9em;
	display: inline-block;
	*display: inline;
	zoom: 1;
	border-bottom-width: 0;
	outline: 0;
}

.t-tabstrip-items .t-icon,
.t-panelbar .t-tabstrip-items .t-icon {
	margin: -1px 4px 0 -3px;
	vertical-align: top;
}

.t-tabstrip-items .t-item .t-image,
.t-tabstrip-items .t-item .t-sprite,
.t-panelbar .t-tabstrip-items .t-item .t-image,
.t-panelbar .t-tabstrip-items .t-item .t-sprite {
	vertical-align: middle;
	margin: -3px 3px 0 -6px;
}

* html .t-tabstrip-items .t-item .t-image,
* html .t-tabstrip-items .t-item .t-sprite {
	vertical-align: top;
	margin-top: -1px;
}

*+html .t-tabstrip-items .t-item .t-image,
*+html .t-tabstrip-items .t-item .t-sprite {
	vertical-align: top;
	margin-top: -1px;
}

.t-tabstrip .t-content,
.t-panelbar .t-tabstrip .t-content {
	border-width: 1px;
	border-style: solid;
	margin: 0 .3em .3em;
	padding: .3em 1em;
	display: none;
	position: static;
	zoom: 1;
}

.t-panelbar {
	zoom: 1;
}

.t-panelbar .t-item {
	list-style-type: none;
	display: block;
	zoom: 1;
	margin: 0;
	top: 0;
	border-width: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.t-panelbar .t-image,
.t-panelbar .t-sprite {
	vertical-align: middle;
	margin-right: 5px;
	margin-top: 4px;
	float: left;
}

.t-panelbar .t-group .t-image,
.t-panelbar .t-group .t-sprite {
	margin-top: 1px;
}

.t-panelbar .t-link {
	line-height: 2.05em;
	padding: 0 1em;
	border-bottom: 1px solid;
	display: block;
	position: relative;
	text-decoration: none;
	zoom: 1;
}

ul.t-panelbar .t-state-hover {
	background-image: none;
}

.t-panelbar-expand,
.t-panelbar-collapse {
	position: absolute;
	top: 5px;
	right: 2px;
}

.t-panelbar .t-group,
.t-panelbar .t-content {
	position: relative;
	zoom: 1;
	padding: 0;
	margin: 0;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.t-panelbar .t-group .t-link {
	line-height: 1.7em;
	border-bottom: 0;
	font-size: .95em;
}

.t-panelbar .t-group .t-arrow-up,
.t-panelbar .t-group .t-arrow-down {
	top: 2px;
}

.t-panelbar .t-group .t-group .t-link {
	padding-left: 2em;
}

.t-panelbar .t-last .t-link {
	border-bottom: 0;
}

.t-panelbar .t-group .t-group {
	border-bottom: 0;
}

.t-tabstrip .t-panelbar .t-content {
	display: block;
}

.t-menu {
	cursor: default;
}

.t-menu,
.t-menu .t-group {
	list-style: none;
	padding: 0;
	margin: 0;
	zoom: 1;
}

.t-menu .t-group .t-link {
	padding-right: 2em;
}

.t-menu .t-item {
	position: relative;
	display: inline-block;
	*display: inline;
	zoom: 1;
	border-width: 0 1px 0 0;
	border-style: solid;
	vertical-align: top;
}

.t-menu-vertical .t-item {
	display: block;
	border-width: 0;
}

.t-menu .t-image,
.t-menu .t-sprite {
	margin: 0 4px 0 -4px;
	vertical-align: top;
}

.t-menu .t-link {
	text-decoration: none;
	padding: .25em .97em;
	display: block;
}

.t-menu .t-group {
	display: none;
	white-space: nowrap;
	border-style: solid;
	border-width: 1px;
	overflow: visible;
}

.t-menu .t-group .t-item {
	display: block;
	border-width: 0;
}

.t-menu .t-group .t-link {
	zoom: 1;
}

.t-menu .t-arrow-down {
	margin-left: 2px;
	margin-right: -10px;
	vertical-align: top;
}

.t-menu .t-arrow-next {
	position: absolute;
	right: 0;
	top: 3px;
}

.t-menu .t-animation-container,
.t-menu .t-group {
	left: -1px;
	position: absolute;
}

.t-menu .t-animation-container .t-animation-container,
.t-menu .t-group .t-group {
	left: 100%;
	top: -1px;
}

.t-menu-vertical .t-animation-container,
.t-menu-vertical .t-group {
	left: 100%;
	top: -1px;
}

.t-menu .t-animation-container .t-group {
	left: auto;
	top: auto;
}

.t-grid {
	position: relative;
	zoom: 1;
}

.t-grid table {
	width: 100%;
	border-collapse: separate;
	empty-cells: show;
	border: 0;
}

.t-grid-header .t-header {
	text-align: left;
}

.t-grid-header .t-header,
.t-grid-header .t-last-header {
	// padding:.3em .6em; /*joa*/
	// border-width:0 1px 1px 0;
	// border-style:solid;	
	font-weight: normal;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.t-grid-header .t-last-header {
	border-right-width: 0;
}

.t-grid-header .t-header .t-link {
	// line-height: 18px;
	// height: 18px;
	display: block;
	// margin: -.3em -.6em;
	// padding:.3em 2.4em .3em .6em; /*joa*/	
	border: 0;
}

.t-grid-header .t-header .t-icon {
	position: static;
}

.t-grid .t-state-hover {
	cursor: pointer;
}

.t-grid td {
	// padding:.25em .6em; /*joa*/
	// border-style:solid; /*ell*/
	// border-width:0; /*ell*/
	// border-right-width:1px; /*ell*/	
	vertical-align: middle;
	// line-height:1.6em;	
	// overflow: hidden;
	// text-overflow: ellipsis;
}

.t-grid .t-last {
	border-right-width: 0;
}

.t-grid-header-wrap,
.t-grid-footer-wrap {
	position: relative;
	overflow: hidden;
	width: 100%;
	zoom: 1;
}

/*
div.t-grid-header,div.t-grid-footer {
	padding-right:17px;
	zoom:1;
	border-bottom-width:1px;
	border-bottom-style:solid;
}
*/

div.t-grid-header .t-header,
div.t-grid-header .t-last-header {
	border-bottom-width: 0;
}

div.t-grid-header .t-last-header {
	border-right-width: 1px;
}

.t-grid-content {
	position: relative;
	overflow: auto;
	overflow-x: auto;
	overflow-y: scroll;
	width: 100%;
	zoom: 1;
}

.t-grid-header table,
.t-grid-content table,
.t-grid-footer table {
	table-layout: fixed;
}

* html .t-grid-content table {
	width: auto;
}

*+html .t-grid-content table {
	width: auto;
}

.t-grid-toolbar {
	border-width: 1px 0 0;
	border-style: solid;
}

.t-grid-pager {
	// joa
	// padding:.2em .6em;
	// line-height:1.8em;
	// overflow:auto;
	// border-width:1px 0 0;
	// border-style:solid;
}

.t-grid-pager:first-child,
.t-grouping-header+.t-grid-pager,
.t-grid-toolbar:first-child,
.t-grouping-header+.t-grid-toolbar {
	border-width: 0 0 1px;
}

* html .t-grid-pager input {
	vertical-align: middle;
}

*+html .t-grid-pager input {
	vertical-align: middle;
}

.t-grid .t-pager,
.t-grid .t-status,
.t-grid .t-status-text,
.t-pager .t-numeric,
.t-pager .t-page-i-of-n,
.t-pager .t-link,
.t-pager span,
.t-pager input,
.t-pager .t-page-size,
.t-grouping-row p {
	display: inline-block;
	*display: inline;
	zoom: 1;
	vertical-align: middle;
}

.t-pager .t-page-size {
	line-height: 1.2em;
}

.t-footer-template td {
	border-width: 1px 1px 0 0;
	border-style: solid;
}

.t-group-footer td,
.t-group-footer .t-last {
	border-width: 1px 0;
	border-style: solid;
}

.t-grid-footer {
	border-style: solid;
	border-width: 1px 0 0;
}

.t-grid-footer td {
	border-top-width: 0;
}

.t-grid .t-status {
	float: left;
	margin: -.2em .6em -.2em -.6em;
	padding: .2em .6em;
	height: 1.85em;
	border-right-width: 1px;
	border-right-style: solid;
}

.t-grid .t-pager {
	margin-top: 10px;	
	float: right; /* ell */	
	cursor: default; /* ell */

	@media screen and ( max-width: 630px ) {
		float: none;
		display: block;
		text-align: center;
	}
}

.t-grid .t-status-text {
	float: right;
	margin-right: .2em;
}

.t-pager .t-link {
	text-decoration: none;
	padding: 1px;
	margin-top: 0;
	border: 0;
	line-height: 16px;

	@media screen and ( max-width: 630px ) {
		padding: 0 10px;
		line-height: 28px;
	}
}

.t-pager .t-state-active,
.t-pager .t-state-hover {
	// padding: 0;
	// border-width: 1px;
	// border-style: solid;
	// -moz-border-radius: 5px;
	// -webkit-border-radius: 5px;
	// border-radius: 5px;
	// border-color: #cccccc;
}

@media screen and ( min-width: 631px ) {
	.t-pager span,
	.t-pager .t-link {
		vertical-align: top;
	}

	.t-pager .t-numeric,
	.t-pager .t-page-i-of-n {
		margin: 0 7px;
		vertical-align: top;
	}
}

.t-numeric .t-link,
.t-numeric .t-state-active {
	margin-top: 0;
	padding: 0 5px;
	line-height: 22px;
	vertical-align: top;

	@media screen and ( max-width: 630px ) {
		padding: 0 10px;
		line-height: 28px;
	}
}

.t-numeric .t-link {
	color: $pagination-color;
}

// .t-numeric .t-link:hover,
// .t-numeric .t-link:focus
// Effet pas seulement sur les chiffres mais aussi sur les flèches
.t-pager .t-link:not(.t-state-disabled):hover,
.t-pager .t-link:not(.t-state-disabled):focus,
// Et sur l'état actif
.t-pager .t-state-active {
	color: $pagination-color-hover;
	background-color: $pagination-bg-color-hover;
}

.t-numeric .t-state-active,
.t-numeric .t-state-hover {
	// padding: 0 4px;
	// line-height: 20px;
}

.t-page-i-of-n input {
	width: 2em;
	vertical-align: baseline;
	font-size: 1em;
}

.t-grid-pager .t-status .t-icon {
	vertical-align: top;
	margin-top: 3px;
}

.t-grid-filter {
	float: right;
	height: 18px;
	padding: .3em .2em;
	position: relative;
	display: inline-block;
	*display: inline;
	zoom: 1;
	margin: -1.8em -.6em -.3em 3px;
}

.t-grid-filter .t-icon {
	vertical-align: top;
}

.t-grid .t-animation-container {
	position: absolute;
}

.t-grid .t-filter-options {
	position: absolute;
	border-width: 1px;
	border-style: solid;
	padding: 3px;
	width: 148px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

.t-filter-options .t-filter-help-text,
.t-filter-options select,
.t-filter-options input,
.t-filter-options .t-button,
.t-filter-options .t-datepicker {
	display: block;
	margin-bottom: 4px;
}

.t-filter-options select {
	width: 148px;
}

.t-filter-options input {
	width: 142px;
}

.t-filter-options .t-datepicker {
	width: 100%;
}

.t-filter-options .t-filter-button {
	margin-bottom: 0;
}

.t-grouping-row .t-icon {
	margin: 0 4px;
}

.t-grouping-row p {
	padding: 0 .6em;
	margin-left: -.6em;
}

.t-grid td.t-group-cell,
.t-grid td.t-hierarchy-cell {
	border-right: 0;
}

.t-grid .t-group-col,
.t-grid .t-hierarchy-col,
// Ajout CDI
.t-grid .t-hierarchy-cell {
	width: 30px;
}

.t-grouping-header {
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.t-grouping-header,
.t-grouping-dropclue {
	height: 24px;
	line-height: 24px;
}

.t-grouping-dropclue {
	position: absolute;
	width: 6px;
}

.t-grouping-header .t-group-indicator {
	display: inline-block;
	*display: inline;
	zoom: 1;
	border-width: 1px;
	border-style: solid;
	line-height: 1.5em;
	padding: .15em .15em .15em .4em;
	margin: 0 3px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

.t-grouping-header .t-link {
	text-decoration: none;
	display: inline-block;
	*display: inline;
	zoom: 1;
	line-height: normal;
	padding: 0;
	border-style: none;
}

.t-grouping-header .t-button {
	padding: 0;
	border: 0;
	background: transparent;
	line-height: 1;
}

.t-grouping-header .t-link .t-icon {
	margin: 0 0 0 -3px;
}

.t-grouping-header .t-button .t-icon {
	margin: 0 0 0 3px;
}

.t-grouping-header a,
.t-grouping-header .t-button {
	display: inline-block;
	*display: inline;
	zoom: 1;
	vertical-align: middle;
}

.t-dirty {
	width: 0;
	height: 0;
	vertical-align: top;
	position: absolute;
	padding: 0;
	margin: -0.45em 0 0 -0.6em;
	overflow: hidden;
	border-width: 3px;
	border-style: solid;
	border-color: #f00 transparent transparent #f00;
}

* html .t-dirty {
	border-color: #f00 pink pink #f00;
	filter: chroma(color=pink);
}

.t-grouping-header,
.t-grid-toolbar {
	cursor: default;
	margin: 0;
	padding: .25em;
}

.t-grid .t-edit-container {
	padding: 0;
	border-right: 0;
}

.t-edit-form {
	padding: 0;
	margin: 0;
}

.t-grid-edit-row .t-edit-form td {
	border-bottom-width: 0;
}

.t-grid-edit-row .text-box,
.t-grid-edit-row .t-numerictextbox,
.t-grid-edit-row .t-datepicker {
	vertical-align: middle;
	width: 5em;
	max-width: 90%;
	min-width: 90%;
	margin: -.4em -.3em -.15em;
}

.t-grid-edit-row .t-dropdown,
.t-grid-edit-row .t-combobox {
	width: 5em;
	max-width: 90%;
	min-width: 90%;
	line-height: normal;
	margin: -.1em -.3em -.45em;
}

* html .t-grid .text-box {
	width: 90%;
}

.t-edit-form-container {
	position: relative;
}

.t-grid .field-validation-error {
	display: block;
}

.t-grid .input-validation-error {
	border-style: ridge;
	border-color: #f00;
	background-color: #ffc0cb;
}

.t-grid-toolbar .t-button {
	vertical-align: middle;
}

.t-grid-actions {
	display: inline-block;
}

* html .t-grid-actions {
	display: inline;
	zoom: 1;
	vertical-align: bottom;
}

*+html .t-grid-actions {
	display: inline;
	zoom: 1;
	vertical-align: bottom;
}

.t-grid .t-button,
.t-edit-form .t-button {
	margin: 0 .2em;
}

.t-grid tbody .t-button {
	min-width: 64px;
}

.t-grid tbody button.t-button,
#ie8#skips {
	min-width: 78px;
}

*+html .t-grid tbody a.t-button {
	min-width: 62px;
}

html body div.t-grid tbody .t-button-icon {
	min-width: 0;
	width: auto;
}

.t-detail-row {
	position: relative;
}

.t-grid .t-detail-cell {
	overflow: visible;
}

.t-grid-resize-indicator {
	position: absolute;
	width: 2px;
	background-color: #aaa;
}

.t-grid .t-resize-handle {
	cursor: col-resize;
	position: absolute;
	height: 25px;
}

.t-combobox,
.t-dropdown,
.t-selectbox {
	display: inline-block;
	*display: inline;
	zoom: 1;
	position: relative;
	overflow: hidden;
	white-space: nowrap;
	width: 150px;
	border-width: 0;
}

*+html .t-combobox {
	overflow: visible;
}

* html .t-combobox {
	overflow: visible;
}

.t-dropdown-wrap,
.t-picker-wrap {
	position: relative;
	display: block;
	cursor: default;
}

.t-picker-wrap {
	padding: 0 25px 0 0;
}

.t-dropdown-wrap {
	padding-right: 16px;
	border-width: 1px;
	border-style: solid;
}

.t-combobox>.t-dropdown-wrap {
	padding-right: 22px;
}

* html .t-combobox .t-dropdown-wrap {
	height: 20px;
}

* html .t-picker-wrap {
	height: 20px;
	padding-right: 20px;
}

.t-picker-wrap .t-input,
.t-combobox .t-input {
	vertical-align: top;
	width: 100%;
}

.t-dropdown .t-input,
.t-combobox .t-input,
.t-selectbox .t-input {
	outline: 0;
	border-width: 0;
}

.t-dropdown .t-input,
.t-selectbox .t-input {
	background: transparent;
}

*+html .t-combobox .t-input {
	margin: -1px 0;
}

* html .t-combobox .t-input {
	margin: -1px 0;
}

* html {
	position: absolute;
	top: 0;
}

.t-picker-wrap .t-select,
.t-dropdown-wrap .t-select {
	text-decoration: none;
	vertical-align: top;
	display: inline-block;
	*display: inline;
	zoom: 1;
	cursor: default;
	position: absolute;
	top: 0;
	right: 0;
}

* html .t-picker-wrap .t-select,
* html .t-dropdown-wrap .t-select {
	top: 1px;
	right: 1px;
}

.t-combobox .t-select {
	border-width: 0 0 0 1px;
	border-style: solid;
	border-color: inherit;
}

.t-combobox .t-icon {
	margin-top: 1px;
}

.t-dropdown .t-select,
.t-selectbox .t-select {
	border: 0;
	text-decoration: none;
	font: inherit;
	color: inherit;
	overflow: hidden;
	cursor: default;
}

.t-dropdown .t-input,
.t-selectbox .t-input {
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
	padding: .2em 0 .2em 3px;
}

.t-picker-wrap .t-select,
.t-dropdown-wrap .t-select {
	position: absolute;
	right: 0;
	top: 0;
}

.t-picker-wrap .t-icon {
	vertical-align: top;
	margin-top: 3px;
}

.t-dropdown-wrap .t-icon {
	vertical-align: top;
	margin-top: 2px;
}

.t-combobox .t-input {
	height: 18px;
	line-height: 18px;
	padding: 1px 3px;
	border: 0;
	margin: 0;
}

.t-picker-wrap .t-select,
.t-dropdown-wrap .t-select {
	height: 20px;
	line-height: 20px;
}

.t-popup {
	border-width: 1px;
	border-style: solid;
}

.t-popup .t-item {
	padding: 1px 5px 1px 3px;
	cursor: default;
}

.t-calendar {
	display: inline-block;
	*display: inline;
	zoom: 1;
	width: 203px;
	position: relative;
	overflow: hidden;
}

.t-calendar .t-link {
	text-decoration: none;
}

.t-calendar .t-action-link {
	text-decoration: underline;
}

.t-calendar .t-header {
	padding: .4em 0;
	text-align: center;
	position: relative;
	zoom: 1;
}

.t-calendar .t-nav-prev,
.t-calendar .t-nav-next {
	position: absolute;
	top: .3em;
}

.t-calendar .t-nav-prev {
	left: 1%;
}

.t-calendar .t-nav-next {
	right: 1%;
}

.t-calendar .t-content {
	text-align: right;
	display: table;
	width: 100%;
	height: 169px;
	table-layout: fixed;
	border-style: none;
	margin: 0;
	padding: 0;
}

*+html .t-calendar .t-content {
	display: block;
}

* html .t-calendar .t-content {
	display: block;
}

.t-calendar .t-animation-container .t-content {
	height: 100%;
}

.t-calendar .t-nav-fast {
	display: inline-block;
	*display: inline;
	zoom: 1;
	width: 75%;
}

.t-calendar .t-nav-fast .t-icon {
	vertical-align: top;
}

.t-calendar th {
	border-bottom-style: solid;
	border-bottom-width: 1px;
	font-weight: normal;
	cursor: default;
}

.t-calendar td {
	cursor: pointer;
	padding: 1px;
}

.t-calendar .t-state-focus {
	border-width: 1px;
	border-style: dotted;
	padding: 0;
}

.t-calendar .t-content .t-link {
	display: block;
	overflow: hidden;
	position: relative;
}

.t-calendar th,
.t-calendar .t-content .t-link {
	padding: .25em .45em .3em .1em;
}

.t-calendar .t-meta-view .t-link {
	padding: .25em 0 .3em;
	text-align: center;
}

.t-timepicker,
.t-datetimepicker,
.t-datepicker {
	border: 0;
	white-space: nowrap;
	// width: 8.5em;
	width: 110px;
}

.t-datetimepicker {
	width: 13em;
}

div.t-timepicker,
div.t-datetimepicker,
div.t-datepicker {
	background: none transparent;
}

.t-datetimepicker .t-picker-wrap {
	padding-right: 44px;
}

* html .t-datetimepicker .t-picker-wrap {
	padding-right: 40px;
}

.t-datetimepicker .t-icon-calendar {
	margin-right: 3px;
}

.t-picker-wrap .t-icon {
	cursor: pointer;
}

.t-state-disabled .t-picker-wrap .t-icon {
	cursor: default;
}

.t-timepicker,
.t-datepicker,
.t-datetimepicker {
	display: inline-block;
	*display: inline;
	zoom: 1;
	vertical-align: top;
}

.t-picker-wrap .t-input {
	margin: 0;
}

.t-iframe-overlay {
	position: absolute;
	width: 100%;
	border: 0;
	top: 0;
	left: 0;
	filter: alpha(opacity=0);
}

.t-time-popup .t-item {
	padding: 1px 3px;
}

div.t-treeview {
	border-width: 0;
	background: none;
	white-space: nowrap;
	overflow: auto;
}

.t-treeview .t-item {
	padding: 0 0 0 16px;
	margin: 0;
	border-width: 0;
	position: static;
	top: auto;
	display: block;
}

.t-treeview .t-group,
.t-treeview .t-content {
	list-style-type: none;
	background: none;
	margin: 0;
	padding: 0;
}

.t-treeview .t-animation-container {
	height: auto!important;
}

.t-treeview .t-icon,
.t-treeview .t-image,
.t-treeview .t-sprite,
.t-treeview .t-in {
	display: inline-block;
	*display: inline;
	zoom: 1;
	vertical-align: top;
}

.t-treeview .t-plus,
.t-treeview .t-minus,
.t-treeview .t-plus-disabled,
.t-treeview .t-minus-disabled {
	margin-left: -16px;
	margin-top: 2px;
	float: left;
	*float: none;
	cursor: pointer;
}

.t-treeview .t-plus-disabled,
.t-treeview .t-minus-disabled {
	cursor: default;
}

.t-treeview .t-sprite,
.t-treeview .t-image {
	margin-right: 3px;
}

.t-treeview .t-in {
	margin: 1px 0;
	padding: 2px 4px 2px 3px;
	text-decoration: none;
	line-height: 16px;
	margin-left: 2px;
}

.t-treeview span.t-in {
	cursor: default;
}

.t-treeview .t-state-hover,
.t-treeview .t-state-selected {
	padding: 1px 3px 1px 2px;
	border-width: 1px;
	border-style: solid;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

.t-treeview .t-drop-clue {
	width: 80px;
	visibility: hidden;
	height: 5px;
	position: absolute;
	margin-top: -3px;
	z-index: 10000;
	background-color: transparent;
	background-repeat: no-repeat;
}

.t-treeview-lines .t-top,
.t-treeview-lines .t-mid,
.t-treeview-lines .t-bot {
	background-repeat: no-repeat;
	margin-left: -16px;
	padding-left: 16px;
}

.t-treeview-lines .t-top {
	background-position: -91px 0;
}

.t-treeview-lines .t-bot {
	background-position: -69px -22px;
}

.t-treeview-lines .t-mid {
	background-position: -47px -44px;
}

.t-treeview-lines .t-last .t-top {
	background-position: -25px -66px;
}

.t-treeview-lines .t-group .t-last .t-bot {
	background-position: -69px -22px;
}

.t-treeview-lines .t-item {
	background-repeat: no-repeat;
}

.t-treeview-lines .t-first {
	background-repeat: no-repeat;
	background-position: 0 16px;
}

div.t-numerictextbox {
	display: inline-block;
	*display: inline;
	zoom: 1;
	vertical-align: middle;
	white-space: nowrap;
	border: 0;
	background: transparent;
}

.t-numerictextbox .t-input {
	margin: 0;
}

.t-formatted-value {
	position: absolute;
	padding: 3px 4px 2px 3px;
}

.t-numerictextbox .t-icon {
	margin-left: -16px;
}

.t-numerictextbox .t-link {
	border-style: none;
	display: inline-block;
	*display: inline;
	zoom: 1;
}

.t-numerictextbox .t-arrow-up {
	vertical-align: top;
	margin-top: 1px;
	height: 10px;
}

.t-numerictextbox .t-arrow-down {
	vertical-align: bottom;
	margin-bottom: 1px;
	height: 11px;
}

.t-colorpicker {
	display: inline-block;
	*display: inline;
	zoom: 1;
	vertical-align: middle;
}

.t-colorpicker .t-tool-icon {
	display: inline-block;
	*display: inline;
	zoom: 1;
	vertical-align: top;
	padding: 2px 3px;
	font-size: 0;
	line-height: 0;
}

.t-colorpicker .t-selected-color {
	display: block;
	height: 16px;
	width: 16px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
}

.t-colorpicker .t-icon {
	vertical-align: middle;
	margin-top: 2px;
}

.t-colorpicker-popup {
	line-height: 0;
	width: 156px;
}

.t-colorpicker-popup .t-reset {
	padding: .25em;
	margin: 0;
	display: inline-block;
	*display: inline;
	zoom: 1;
}

.t-colorpicker-popup .t-item {
	float: left;
	display: block;
	overflow: hidden;
	width: 12px;
	height: 12px;
	font-size: 0;
	padding: 0;
	margin: 1px 2px 2px 1px;
}

.t-colorpicker-popup .t-item div {
	width: 12px;
	height: 12px;
	margin: 0;
}

.t-colorpicker-popup .t-state-selected {
	margin: 0 1px 1px 0;
	border: 1px solid #000;
	background-color: #fff;
}

.t-colorpicker-popup .t-state-selected div {
	width: 10px;
	height: 10px;
	margin: 1px;
}

.t-editor {
	border-width: 1px;
	border-style: solid;
	border-collapse: separate;
	height: 250px;
	font-size: 100%;
	table-layout: fixed;
	vertical-align: top;
	width: 100%;
}

.t-editor .t-editor-toolbar-wrap {
	border: 0;
	padding: 0;
}

.t-editor-toolbar {
	margin: 0;
	padding: .1em 0;
	list-style-type: none;
	cursor: default;
	line-height: 1.3em;
}

.t-editor-toolbar li {
	display: inline;
	vertical-align: middle;
}

.t-editor-toolbar .t-editor-dropdown,
.t-editor-toolbar .t-editor-combobox,
.t-editor-toolbar .t-editor-selectbox,
.t-editor-toolbar .t-editor-colorpicker {
	margin-right: 2px;
}

.t-editor-toolbar .t-separator {
	border-width: 0 1px 0 0;
	border-style: solid;
	padding: 0 0 0 1px;
	font-size: 1.3em;
	margin: 0 .15em;
	position: relative;
	top: 1px;
}

.t-editor-toolbar .t-break {
	display: block;
	height: 1px;
	line-height: 0;
	font-size: 0;
}

.t-editor-toolbar .t-dropdown,
.t-editor-toolbar .t-combobox,
.t-editor-toolbar .t-selectbox,
.t-editor-toolbar .t-colorpicker {
	vertical-align: middle;
}

.t-editor-button .t-tool-icon {
	vertical-align: middle;
	width: 22px;
	height: 22px;
	margin: 1px;
}

.t-editor-colorpicker .t-tool-icon {
	padding: 17px 3px 2px;
}

.t-editor-colorpicker .t-icon {
	border-style: solid;
	border-color: #ccc;
	border-width: 0 0 0 1px;
}

.t-editor-colorpicker .t-selected-color {
	height: 3px;
}

.t-editor-combobox .t-input {
	height: 20px;
	line-height: 20px;
}

.t-editor-combobox .t-select {
	height: 22px;
	line-height: 22px;
}

.t-bold {
	background-position: -22px 0;
}

.t-italic {
	background-position: -44px 0;
}

.t-underline {
	background-position: -66px 0;
}

.t-strikethrough {
	background-position: -88px 0;
}

.t-justifyLeft {
	background-position: -110px 0;
}

.t-justifyCenter {
	background-position: -132px 0;
}

.t-justifyRight {
	background-position: -154px 0;
}

.t-justifyFull {
	background-position: -176px 0;
}

.t-insertOrderedList {
	background-position: -198px 0;
}

.t-insertUnorderedList {
	background-position: -220px 0;
}

.t-createLink {
	background-position: -242px 0;
}

.t-unlink {
	background-position: -264px 0;
}

.t-insertImage {
	background-position: -286px 0;
}

.t-foreColor .t-tool-icon {
	background-position: -308px -2px;
}

.t-backColor .t-tool-icon {
	background-position: -330px -2px;
}

.t-indent {
	background-position: -352px 0;
}

.t-outdent {
	background-position: -374px 0;
}

.t-fontName {
	width: 110px;
}

.t-fontSize {
	width: 124px;
}

.t-formatBlock {
	width: 147px;
}

.t-editor-button .t-state-hover,
.t-editor-button .t-state-active {
	vertical-align: middle;
	border-width: 1px;
	border-style: solid;
	margin: 0;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

.t-editor-button .t-state-disabled {
	opacity: .3;
	filter: alpha(opacity=30);
}

.t-editor .t-editable-area {
	outline: none;
	height: 100%;
	width: 100%;
	border-width: 1px;
	border-style: solid;
}

.t-editor .t-content {
	height: 100%;
	width: 100%;
	display: block;
	padding: 0;
	margin: 0;
	border: 0;
}

.t-editor iframe.t-content {
	display: inline;
	vertical-align: top;
}

.t-editor .t-raw-content {
	border: 0;
	margin: 0;
	padding: 0;
	font-size: inherit;
	font-family: Consolas, "Courier New", monospace;
}

.t-editor-dialog {
	padding: 1em;
}

.t-editor-dialog ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

.t-form-text-row,
.t-form-checkbox-row {
	padding-bottom: 1em;
}

.t-form-text-row label {
	display: inline-block;
	*display: inline;
	zoom: 1;
	width: 7em;
	text-align: right;
	padding-right: .5em;
}

.t-form-checkbox-row input {
	margin-left: 7em;
	font-size: inherit;
	padding: 0;
}

.t-form-checkbox-row label {
	padding-left: .3em;
}

.t-form-checkbox-row input,
.t-form-checkbox-row label,
.t-form-text-row label .t-form-text-row input,
.t-form-text-row select {
	vertical-align: middle;
}

.t-form-text-row input,
.t-form-text-row select {
	width: 20em;
}

.t-editor-dialog .t-button-wrapper {
	padding-top: .5em;
	text-align: right;
}

.t-editor-dialog .t-button {
	display: inline-block;
	*display: inline;
	zoom: 1;
}

.t-drag-clue {
	border-width: 1px;
	border-style: solid;
	font-size: .9em;
	padding: .05em .15em;
	position: absolute;
	z-index: 10003;
	white-space: nowrap;
	cursor: default;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

.t-drag-status {
	margin-right: 4px;
	vertical-align: top;
	margin-top: -1px;
}

.t-reorder-cue {
	position: absolute;
	width: 1px;
	overflow: visible;
}

.t-reorder-cue .t-icon {
	width: 8px;
	height: 4px;
	position: absolute;
	left: -4px;
}

.t-reorder-cue .t-arrow-down {
	top: -4px;
	background-position: -20px -182px;
}

.t-reorder-cue .t-arrow-up {
	bottom: -4px;
	background-position: -20px -166px;
}

div.t-slider {
	position: relative;
	border-width: 0;
	background-color: transparent;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.t-slider-vertical {
	width: 22px;
	height: 200px;
}

.t-slider-horizontal {
	display: inline-block;
	height: 22px;
	width: 200px;
}

*+html .t-slider-horizontal {
	display: inline;
	zoom: 1;
}

* html .t-slider-horizontal {
	display: inline;
	zoom: 1;
}

.t-slider-wrap {
	width: 100%;
	height: 100%;
}

.t-slider .t-button,
.t-grid .t-slider .t-button {
	position: absolute;
	top: 0;
	min-width: 0;
	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0;
	outline: none;
}

.t-slider .t-button .t-icon {
	vertical-align: top;
	margin-top: 2px;
}

.t-state-disabled .t-slider-wrap {
	opacity: .6;
	filter: alpha(opacity=60);
}

.t-state-disabled .t-slider-wrap .t-slider-items {
	color: #333;
}

.t-slider .t-button-decrease {
	left: 0;
}

.t-slider-vertical .t-button-decrease,
.t-grid .t-slider-vertical .t-button-decrease {
	top: auto;
	bottom: 0;
}

.t-slider .t-button-increase {
	right: 0;
}

.t-slider .t-icon {
	cursor: pointer;
}

.t-state-disabled .t-button,
.t-state-disabled .t-draghandle,
.t-state-disabled .t-slider-wrap .t-icon,
.t-state-disabled .t-slider .t-icon {
	cursor: default;
}

*+html .t-slider .t-icon {
	margin-top: 2px;
}

* html .t-slider .t-icon {
	margin-top: 2px;
}

.t-slider-track,
.t-slider-selection {
	position: absolute;
	margin: 0;
	padding: 0;
}

.t-slider-horizontal .t-slider-track,
.t-slider-horizontal .t-slider-selection {
	height: 6px;
	top: 50%;
	left: 0;
	margin-top: -3px;
	background-repeat: repeat-x;
}

.t-slider-horizontal .t-slider-track {
	border-width: 0 1px;
	border-style: solid;
	background-position: 0 -464px;
}

.t-slider-horizontal .t-slider-selection {
	background-position: 0 -470px;
}

.t-slider-horizontal .t-slider-buttons .t-slider-track {
	left: 27px;
}

.t-slider-vertical .t-slider-track,
.t-slider-vertical .t-slider-selection {
	width: 6px;
	left: 50%;
	bottom: 0;
	margin-left: -3px;
	background-repeat: repeat-y;
}

.t-slider-vertical .t-slider-track {
	border-width: 1px 0;
	border-style: solid;
	background-position: 0 0;
}

.t-slider-vertical .t-slider-selection {
	background-position: -6px 0;
}

.t-slider-vertical .t-slider-buttons .t-slider-track {
	bottom: 27px;
}

.t-draghandle {
	position: absolute;
	background-repeat: no-repeat;
	background-color: transparent;
	text-indent: -3333px;
	text-decoration: none;
	text-align: center;
	overflow: hidden;
	cursor: pointer;
}

.t-slider-horizontal .t-draghandle {
	top: -7px;
	width: 11px;
	height: 20px;
}

.t-slider-vertical .t-draghandle {
	left: -7px;
	width: 20px;
	height: 11px;
}

.t-slider-horizontal .t-draghandle,
.t-state-disabled.t-slider-horizontal a.t-draghandle,
.t-state-disabled .t-slider-horizontal a.t-draghandle {
	background-position: 0 -368px;
}

.t-slider-horizontal .t-draghandle:hover,
.t-slider-horizontal .t-draghandle:focus {
	background-position: 0 -400px;
}

.t-slider-horizontal a.t-draghandle.t-state-active {
	background-position: 0 -432px;
}

.t-slider-vertical .t-draghandle,
.t-state-disabled.t-slider-vertical a.t-draghandle,
.t-state-disabled .t-slider-vertical a.t-draghandle {
	background-position: -32px -368px;
}

.t-slider-vertical .t-draghandle:hover,
.t-slider-vertical .t-draghandle:focus {
	background-position: -32px -400px;
}

.t-slider-vertical a.t-draghandle.t-state-active {
	background-position: -32px -432px;
}

.t-slider-items {
	display: inline-block;
}

.t-slider-items {
	display: block;
}

.t-slider-items:after {
	content: "";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

.t-slider-buttons .t-slider-items {
	margin-left: 27px;
}

.t-slider-horizontal .t-slider-items {
	height: 100%;
}

.t-slider-vertical .t-slider-items {
	padding-top: 1px;
}

.t-slider-vertical .t-slider-buttons .t-slider-items {
	padding-top: 0;
}

.t-slider-vertical .t-slider-buttons .t-slider-items {
	margin: 0;
	padding-top: 28px;
}

.t-slider .t-tick {
	position: relative;
	margin: 0;
	padding: 0;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center center;
	cursor: default;
}

.t-slider-horizontal .t-tick {
	float: left;
	text-align: center;
	height: 100%;
}

*+html .t-slider-vertical .t-tick {
	float: left;
	clear: left;
	width: 100%;
}

* html .t-slider-vertical .t-tick {
	float: left;
	clear: left;
	width: 100%;
}

.t-slider-horizontal .t-tick {
	background-position: center -94px;
}

.t-slider-horizontal .t-slider-topleft .t-tick {
	background-position: center -124px;
}

.t-slider-horizontal .t-slider-bottomright .t-tick {
	background-position: center -154px;
}

.t-slider-horizontal .t-tick-large {
	background-position: center -4px;
}

.t-slider-horizontal .t-slider-topleft .t-tick-large {
	background-position: center -34px;
}

.t-slider-horizontal .t-slider-bottomright .t-tick-large {
	background-position: center -64px;
}

.t-slider-vertical .t-tick {
	background-position: -94px center;
}

.t-slider-vertical .t-slider-topleft .t-tick {
	background-position: -124px center;
}

.t-slider-vertical .t-slider-bottomright .t-tick {
	background-position: -154px center;
}

.t-slider-vertical .t-tick-large {
	background-position: -4px center;
}

.t-slider-vertical .t-slider-topleft .t-tick-large {
	background-position: -34px center;
}

.t-slider-vertical .t-slider-bottomright .t-tick-large {
	background-position: -64px center;
}

.t-slider-horizontal .t-first {
	background-position: 0 -4px;
}

.t-slider-horizontal .t-slider-topleft .t-first {
	background-position: 0 -34px;
}

.t-slider-horizontal .t-slider-bottomright .t-first {
	background-position: 0 -64px;
}

.t-slider-horizontal .t-last {
	background-position: 100% -4px;
}

.t-slider-horizontal .t-slider-topleft .t-last {
	background-position: 100% -34px;
}

.t-slider-horizontal .t-slider-bottomright .t-last {
	background-position: 100% -64px;
}

.t-slider-vertical .t-first {
	background-position: -4px 100%;
}

.t-slider-vertical .t-slider-topleft .t-first {
	background-position: -34px 100%;
}

.t-slider-vertical .t-slider-bottomright .t-first {
	background-position: -64px 100%;
}

.t-slider-vertical .t-last {
	background-position: -4px 0;
}

.t-slider-vertical .t-slider-topleft .t-last {
	background-position: -34px 0;
}

.t-slider-vertical .t-slider-bottomright .t-last {
	background-position: -64px 0;
}

.t-slider-vertical .t-tick {
	text-align: right;
}

.t-slider-vertical .t-slider-topleft .t-tick {
	text-align: left;
}

.t-slider .t-label {
	position: absolute;
	white-space: nowrap;
}

.t-slider-horizontal .t-label {
	left: 0;
	width: 100%;
}

.t-slider-horizontal .t-first .t-label {
	left: -50%;
}

.t-slider-horizontal .t-last .t-label {
	left: auto;
	right: -50%;
}

.t-slider-horizontal .t-label {
	bottom: -1.2em;
}

.t-slider-horizontal .t-slider-topleft .t-label {
	top: -1.2em;
}

.t-slider-vertical .t-label {
	left: 120%;
	display: block;
	text-align: left;
}

.t-slider-vertical .t-last .t-label {
	top: -0.5em;
}

.t-slider-vertical .t-first .t-label {
	bottom: -0.5em;
}

.t-slider-vertical .t-slider-topleft .t-label {
	left: auto;
	right: 120%;
}

.t-tooltip {
	position: absolute;
	border-width: 1px;
	border-style: solid;
	padding: 4px 5px;
	background-repeat: repeat-x;
	z-index: 10002;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	min-width: 10px;
	text-align: center;
}

.t-callout {
	position: absolute;
}

.t-callout-n,
.t-callout-s {
	width: 9px;
	height: 6px;
}

.t-callout-w,
.t-callout-e {
	width: 6px;
	height: 9px;
}

.t-callout-n {
	top: -6px;
	left: 50%;
	margin-left: -4px;
	background-position: -64px -368px;
}

.t-callout-w {
	left: -6px;
	top: 50%;
	margin-top: -4px;
	background-position: -64px -384px;
}

.t-callout-s {
	top: 100%;
	left: 50%;
	margin-left: -4px;
	background-position: -64px -400px;
}

.t-callout-e {
	left: 100%;
	top: 50%;
	margin-top: -4px;
	background-position: -64px -416px;
}

.t-splitter {
	position: relative;
	height: 300px;
}

.t-splitter .t-pane {
	overflow: hidden;
}

.t-splitter .t-scrollable {
	overflow: auto;
}

.t-splitter .t-pane-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -8px 0 0 -8px;
}

.t-ghost-splitbar,
.t-splitbar {
	position: absolute;
	border-style: solid;
	font-size: 0;
}

.t-splitter .t-ghost-splitbar-horizontal,
.t-splitter .t-splitbar-horizontal {
	top: 0;
	width: 5px;
	border-width: 0 1px;
	background-repeat: repeat-y;
}

.t-ghost-splitbar-vertical,
.t-splitbar-vertical {
	left: 0;
	height: 5px;
	border-width: 1px 0;
	background-repeat: repeat-x;
}

.t-splitbar-draggable-horizontal {
	cursor: w-resize;
}

.t-splitbar-draggable-vertical {
	cursor: n-resize;
}

.t-splitbar .t-resize-handle {
	display: none;
}

.t-restricted-size-horizontal,
.t-restricted-size-vertical {
	background-color: #f00;
}

.t-splitbar-horizontal .t-icon {
	width: 5px;
	height: 21px;
	position: absolute;
	top: 50%;
	margin-top: -10px;
}

.t-collapse-prev,
.t-collapse-next,
.t-expand-prev,
.t-expand-next {
	cursor: pointer;
}

.t-splitbar-horizontal .t-collapse-prev {
	margin-top: -31px;
}

.t-splitbar-horizontal .t-collapse-next {
	margin-top: 11px;
}

.t-splitbar-vertical .t-icon {
	height: 5px;
	width: 21px;
	position: absolute;
	left: 50%;
	margin-left: -10px;
}

.t-splitbar-vertical .t-collapse-prev {
	margin-left: -31px;
}

.t-splitbar-vertical .t-collapse-next {
	margin-left: 11px;
}

.t-splitbar-draggable-vertical .t-resize-handle,
.t-splitbar-draggable-horizontal .t-resize-handle {
	display: inline-block;
	*display: inline;
	zoom: 1;
}

.t-splitbar-horizontal {
	background-position: 5px 0;
}

.t-splitbar-horizontal-hover {
	background-position: -12px 0;
}

.t-splitbar-horizontal-active,
.t-ghost-splitbar-horizontal {
	background-position: -17px 0;
}

.t-restricted-size-horizontal {
	background-position: -22px 0;
}

.t-splitbar-horizontal .t-resize-handle {
	background-position: -10px -496px;
}

.t-splitbar-horizontal-hover .t-resize-handle {
	background-position: -10px -517px;
}

.t-splitbar-horizontal .t-collapse-prev,
.t-splitbar-horizontal .t-expand-next {
	background-position: 0 -496px;
}

.t-splitbar-horizontal-hover .t-collapse-prev,
.t-splitbar-horizontal-hover .t-expand-next {
	background-position: 0 -517px;
}

.t-splitbar-horizontal .t-collapse-next,
.t-splitbar-horizontal .t-expand-prev {
	background-position: -5px -496px;
}

.t-splitbar-horizontal-hover .t-collapse-next,
.t-splitbar-horizontal-hover .t-expand-prev {
	background-position: -5px -517px;
}

.t-splitbar-vertical {
	background-position: 0 5px;
}

.t-splitbar-vertical-hover {
	background-position: 0 -476px;
}

.t-splitbar-vertical-active,
.t-ghost-splitbar-vertical {
	background-position: 0 -481px;
}

.t-restricted-size-vertical {
	background-position: 0 -486px;
}

.t-splitbar-vertical .t-resize-handle {
	background-position: -16px -496px;
}

.t-splitbar-vertical-hover .t-resize-handle {
	background-position: -16px -501px;
}

.t-splitbar-vertical .t-collapse-prev,
.t-splitbar-vertical .t-expand-next {
	background-position: -16px -506px;
}

.t-splitbar-vertical-hover .t-collapse-prev,
.t-splitbar-vertical-hover .t-expand-next {
	background-position: -16px -517px;
}

.t-splitbar-vertical .t-collapse-next,
.t-splitbar-vertical .t-expand-prev {
	background-position: -16px -511px;
}

.t-splitbar-vertical-hover .t-collapse-next,
.t-splitbar-vertical-hover .t-expand-prev {
	background-position: -16px -522px;
}

html .t-upload {
	position: relative;
	border-width: 0;
	background: none;
}

.t-dropzone,
.t-upload-files {
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

.t-dropzone em,
.t-upload-button {
	vertical-align: middle;
}

*+html .t-dropzone em,
*+html .t-upload-button {
	vertical-align: baseline;
}

* html .t-dropzone em,
* html .t-upload-button {
	vertical-align: baseline;
}

.t-dropzone {
	border-style: solid;
	border-width: 0;
	padding: 4px;
	background-color: transparent;
}

.t-dropzone em {
	margin-left: .6em;
	visibility: hidden;
}

.t-dropzone-active {
	border-width: 1px;
	padding: 3px;
}

.t-dropzone-active em {
	visibility: visible;
}

.t-upload-button {
	width: 86px;
	position: relative;
	direction: ltr;
	overflow: hidden!important;
}

.t-upload-button input {
	font: 200px monospace!important;
	opacity: 0;
	filter: alpha(opacity=0);
	position: absolute;
	padding: 0;
	margin: 0;
	cursor: pointer;
	right: 0;
	top: 0;
	z-index: 1;
}

.t-state-disabled .t-upload-button input {
	cursor: default;
}

.t-upload-files {
	border-style: solid;
	border-width: 1px;
	margin: .2em 0 0;
	padding: .3em .7em .5em;
	line-height: 1.9em;
}

.t-upload-files .t-button {
	position: relative;
	top: 1px;
	margin-left: 8px;
	padding-top: 0;
	padding-bottom: 0;
	font-size: 84%;
}

*+html .t-upload-files .t-button {
	line-height: 1;
}

* html .t-upload-files .t-button {
	line-height: 1;
}

.t-upload .t-delete,
.t-upload .t-cancel,
.t-upload .t-retry {
	height: 8px;
	vertical-align: baseline;
}

.t-upload .t-delete,
.t-upload .t-cancel {
	width: 7px;
	background-position: -5px -292px;
}

.t-retry {
	background-position: -66px -228px;
	width: 11px;
}

.t-success {
	background-position: -64px -240px;
}

.t-fail {
	background-position: -64px -256px;
}

.t-filename {
	position: relative;
	display: inline-block;
	min-width: 120px;
	vertical-align: middle;
}

.t-progress,
.t-progress-status {
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 118px;
	height: 2px;
	border-style: solid;
	border-width: 1px;
	background-repeat: repeat-x;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
}

.t-progress-status {
	left: -1px;
	bottom: -1px;
	width: 50%;
}

.t-upload-selected {
	min-width: 86px;
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	-ms-box-sizing: content-box;
	margin: 3px 0 0;
}

*+html .t-upload-selected {
	min-width: 100px;
}

.t-toolbar-wrap .t-dropzone em,
.t-toolbar-wrap .t-upload-files {
	display: none;
}

.t-toolbar-wrap .t-dropzone {
	border: 0;
	padding: 0;
	background-color: transparent;
}

.t-search-wrap {
	float: right;
	width: auto;
	position: relative;
	padding: 0;
}

.t-search-wrap label {
	position: absolute;
	top: 0;
	left: 4px;
	line-height: 20px;
	font-style: italic;
}

.t-search-wrap input.t-input {
	width: 158px;
	padding-left: 0;
	padding-right: 0;
}

.t-search {
	background-position: -64px -176px;
}

.t-search-wrap .t-search {
	position: absolute;
	top: 1px;
	right: 2px;
}

.t-breadcrumbs {
	float: left;
	position: relative;
	width: 560px;
}

.t-breadcrumbs-wrap {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	padding-left: 5px;
	line-height: 18px;
}

html input.t-breadcrumbs-input {
	width: 100%;
	padding-left: 2px;
	font-family: inherit;
}

.t-breadcrumbs .t-link,
.t-breadcrumbs-wrap .t-icon {
	text-decoration: none;
	vertical-align: middle;
	margin-top: 0;
}

.t-breadcrumbs .t-link:hover {
	text-decoration: underline;
}

.t-imagebrowser .t-breadcrumbs .t-first {
	text-decoration: none;
	cursor: default;
}

.t-imagebrowser .t-toolbar {
	clear: both;
	border-style: solid;
	border-width: 1px;
	margin: 8px 0 0;
	padding: 2px 0 3px;
	background-repeat: repeat-x;
	line-height: 23px;
	white-space: nowrap;
}

.t-imagebrowser .t-upload {
	float: left;
}

.t-imagebrowser .t-upload-button {
	width: auto;
	vertical-align: top;
}

.t-imagebrowser .t-upload .t-icon {
	vertical-align: bottom;
}

*+html .t-imagebrowser .t-upload-button,
*+html .t-imagebrowser .t-upload .t-icon {
	vertical-align: baseline;
	position: relative;
	top: 1px;
}

* html .t-imagebrowser .t-upload-button,
* html .t-imagebrowser .t-upload .t-icon {
	vertical-align: baseline;
	position: relative;
	top: 1px;
}

*+html .t-imagebrowser .t-upload .t-icon {
	top: 2px;
}

* html .t-imagebrowser .t-upload .t-icon {
	top: 2px;
}

*+html .t-imagebrowser .t-toolbar .t-button-icon {
	vertical-align: middle;
}

* html .t-imagebrowser .t-toolbar .t-button-icon {
	vertical-align: middle;
}

.t-addfolder {
	background-position: -64px -192px;
}

.t-goup {
	background-position: -64px -208px;
}

.t-toolbar .t-state-disabled {
	opacity: .5;
	filter: alpha(opacity=50);
}

.t-toolbar-wrap {
	float: left;
}

.t-tiles-arrange {
	float: right;
}

* html .t-tiles-arrange {
	margin: 3px 0 0;
}

.t-tiles-arrange .t-arrow-down {
	margin: 0 2px 0 3px;
}

.t-tiles-arrange a {
	text-decoration: none;
}

.t-tiles {
	clear: both;
	height: 390px;
	border-style: solid;
	border-width: 1px;
	border-top-width: 0;
	margin: 0 0 1.4em;
	padding: 9px;
	overflow: auto;
	line-height: 1.2;
}

.t-tile {
	width: 223px;
	height: 88px;
	overflow: hidden;
	float: left;
	border-width: 1px;
	border-style: solid;
	margin: 1px;
	padding: 0 0 4px;
	cursor: pointer;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

.t-imagebrowser .t-thumb {
	float: left;
	display: inline;
	width: 86px;
	height: 86px;
	margin: 4px 10px 0 4px;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.t-imagebrowser .t-image {
	margin: 2px 0 0 2px;
	-moz-box-shadow: 1px 1px 3px #aaa;
	-webkit-box-shadow: 1px 1px 3px #aaa;
	box-shadow: 1px 1px 3px #aaa;
}

.t-imagebrowser .t-folder {
	width: 80px;
	height: 80px;
	background-position: 0 -200px;
	background-repeat: no-repeat;
}

.t-goback {
	background-position: -64px -224px;
}

.t-imagebrowser .t-loading {
	margin: 35px 0 0 33px;
}

.t-tile strong,
.t-tile input {
	margin: 10px 0 4px;
	font-weight: normal;
}

.t-tile strong {
	float: left;
	width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.t-tile input {
	width: 100px;
}

.t-tile em,
.t-tile .t-filesize {
	clear: left;
	font-weight: normal;
	font-style: normal;
}

.t-tile strong,
.t-tile input,
.t-tile em,
.t-tile .t-filesize {
	display: block;
}

.t-imagebrowser .t-form-text-row input {
	width: 628px;
}

.t-tile-empty {
	margin: 160px 0 0;
}

.t-tile-empty .t-dialog-upload {
	font-weight: bold;
	font-size: 120%;
}

.t-tile-empty strong {
	display: block;
	margin: 0 0 .2em;
	font-size: 3em;
	font-weight: normal;
}

.t-tile-empty,
.t-tile-empty .t-button-wrapper {
	text-align: center;
}

.t-touch-scrollbar {
	position: absolute;
	display: none;
	z-index: 200000;
	height: 8px;
	width: 8px;
	border: 1px solid #8a8a8a;
	background-color: #858585;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
	.t-touch-scrollbar {
		height: 12px;
		width: 12px;
		-webkit-border-radius: 7px;
	}
}

div.t-chart {
	border: none;
	background-color: transparent;
}

// joa
div.t-status {
	display: none;
	visibility: hidden;
}

// joa
div.t-status-text {
	display: none;
	visibility: hidden;
}

div.t-pager {
	float: right;
	padding-right: 5px;
}