@charset "UTF-8";
/**
 * @site            Librairie La Liseuse
 * @author          Joana Henrique
 * @lastmodified    2024-05-21
 * @note            Forms
*/

/* == @section Global ==================== */
textarea,
input[type=email], 
input[type=number], 
input[type=password], 
input[type=search], 
input[type=text] {
    background-color:$base-bg-color;
    padding:5px 10px;
    border:1px solid $border-color;
    border-radius:3px;
    min-height:40px;
    font-size:15px;
}

select {
    border-color:$border-color;
    border-radius:3px;
    min-height:40px;
    padding:0 5px;
    font-size:15px;
    background-color:$base-bg-color;
}

#article {
    .forgottenPasswordPage {
        background-color:$secondary-color-light;
        .inner-container {
            background-color:$base-bg-color;
            padding:30px;
        }
        .section-title {
            text-align: left;
        }
    }
}

label {
    font-size:15px;
    color:$gray;   
}

.forgotPassword {
    .flex {
        align-items: center;
        flex-wrap:wrap;
        label {
            display:block;
            font-size:15px;
            color:$gray;  
            width:100%; 
        }
    }
}

/* == @section Header - Buttons ==================== */
button,
.btnAddBasket,
.btnSendContact,
.basketButton,
.forgottenButton,
input[type=submit] {
    background-color:$secondary-color;
    border:2px solid $secondary-color;
    border-radius:50px;
    color:$base-bg-color;
    padding:0.5em 1.5em;
    font-weight:600;
    font-size:.95em;
    width:fit-content;
    transition: .4s all;
    &:hover {
        background-color:$primary-color;
        border:2px solid $primary-color;
    }
    a {
        color:$base-bg-color;
    }
}

.forgottenButton,
input[type=submit],
.basketButton {
    padding:0.35em 1.4em;
    font-size:16px;
}

/* == @section Header - Compte ==================== */
.account-page {
    &.create-account-page {
        background-color:$secondary-color-light;
        fieldset {
            background-color:$base-bg-color;
            border:none;
            padding:2em;
            width: 96%;
            max-width:720px;
            margin: 0 auto;
            label {
                display:block;
            }
            select,
            input[type=text],
            input[type=password] {
                width:100%;
            }
            .account-details {
                &:not(:first-of-type) {
                    h5 {
                        border-top:1px solid $border-color;
                        margin-top:2.5em;
                        padding-top:2.5em;
                    }
                }
            }

            .compteFormEntree {
                margin-top:1em;
            }
        }
    }
    button {
        margin-top:2em;
    }

    &.account-success-page {
        p {
            text-align: center;
        }
        .btnContinuerAchats {
            display:block;
            margin:0 auto;
        }
    }
}

.t-window {
    min-width:600px;
    .t-window-title {
        font-size:0.8em;
        text-align: center;
        font-weight:600;
    }
    .t-content {
        height:unset!important;
        padding:2em;
    }

    .windowLogin {
        text-align: center;
        a {
            text-decoration: underline;
            font-size:.8em;
            font-weight:500;
            color:$primary-color;
        }
        input[type=text],
        input[type=password] {
            width:calc(50% - 3.5em);
            margin:0em 0.5em;
        }

        .btnSubmitLogin {
            width: 40px;
            height: 40px;
            padding: 10px;
            background-color: $secondary-color;
            border-radius: 5px;
        }
    }
}

#myCheckoutForm {
    .checkout-addresses {
        margin:4em 0;
        label {
            display: block;
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 5px;
        }
        select,
        input {
            margin-bottom:1em;
            width:100%;
        }
    }
    .checkoutAdresseFacturation,
    .checkoutAdresseLivraison {
        width:50%;
        padding:0 1em 0 0;
        td {
            border:none;
            padding:0;
            font-size:.9em;
        }
        a {
            text-decoration:underline;
            font-size:.8em;
            font-weight:600;
            color:$primary-color;
        }
    }

    .checkoutPreferences {
        .preferences {
            label {
                display:inline-block;
                width:250px;
                text-align: right;
            }
            .checkout-paymode {
                display: inline-block;
                font-size:.8em;
            }
            .compteFormEntreeCheckout {
                margin:1em 0;
            }
        }
    }

    .checkoutConditions {
        margin-top:1em;
        padding-top:1em;
        border-top:1px solid $border-color;
        input[type=checkbox] {
            margin-right:3px;
        }
        a {
            text-decoration: underline;
            color:$primary-color;
            vertical-align: initial;
        }
        .laPoste {
            margin:3px 0 0;
        }
        * {
            font-size:14px;
            vertical-align:middle;
        }
    }
}