@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-12
 * @note            Gift Idea Page
 */

.rotator-gift {
	h2:not(:first-child) {
		margin-top: 1.5em;
	}

	h2 + div {
		margin: 0 0 .5em 0;
	}
}