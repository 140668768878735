@charset "UTF-8";
/**
 * @site			Librairie L'intégrale
 * @author			Luc Poupard
 * @lastmodified	2017-10-13
 * @note            Slick 1.6.0
 */

.slick-prev,
.slick-next {
	position: absolute;
	top: 50%;
	margin-top: -7px;
	padding: 0;
	width: 15px;
	height: 14px;
	background: {
		color: transparent;
		repeat: no-repeat;
		position: 2px 1px;
	}
	border: none;
	text-indent: 200%;
	overflow: hidden;
}

[dir="rtl"] .slick-next,
.slick-prev {
	left: -20px;
	background-image: url('#{$img-url-old}/bigRotatorArrowLeft.png');
}

[dir="rtl"] .slick-prev,
.slick-next {
	right: -20px;
	background-image: url('#{$img-url-old}/bigRotatorArrowRight.png');
}