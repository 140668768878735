@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-11-27
 * @note			Configuration du projet
 */


/* == @section Variables  ==================== */
/* -- Couleurs ----------------------------- */
$base-color					: #f1f1f1 !default;
$base-bg-color				: #3f3e3e !default;

$content-color				: #333 !default;
$content-bg-color			: #fff !default;

$primary-color				: #fac400 !default; // Jaune
$secondary-color			: #db9925 !default; // Jaune orangé
$third-color				: #a38103 !default; // "Marroné"
$fourth-color				: #9e1414 !default; // Rouge liens fiches livre

$white						: #fff !default;
$black						: #000 !default;
$grey-dark					: #2d2d2d !default;
$grey-medium				: #666 !default;
$grey-light					: #888 !default;

/* Titres */
$title-color				: $content-color !default;

/* Formulaires */
$fieldset					: #e3e0e5 !default;
$form-border				: #d0d0d0 !default;
$form-bg					: #f7f6f6 !default;

$form-button-color			: $black !default;
$form-button-bg-color		: $primary-color !default;
$form-button-color-hover	: $form-button-color !default;
$form-button-bg-color-hover	: $secondary-color !default;

$error						: #c20a19 !default;

/* Skiplinks - menu mobile */
$skiplink-color				: $content-color !default;
$skiplink-bg-color			: $primary-color !default;

/* Menu principal */
$nav-color					: $black !default;
$nav-bg-color				: $content-bg-color !default;

$nav-link-color				: $nav-color !default;
$nav-link-bg-color			: $nav-bg-color !default;

$nav-link-color-hover		: $grey-medium !default;
$nav-link-bg-color-hover	: $nav-bg-color !default;

/* Panier */
$basket-color				: $base-color !default;
$basket-bg-color			: rgba( $content-bg-color, .2 ) !default;

$basket-bg-color-hover		: rgba( $content-bg-color, .175 ) !default;

/* Liste/détail produit */
$gradient-add-top			: #881414 !default;
$gradient-add-bottom		: #6d0909 !default;
$gradient-add-top-hover		: #9b1313 !default;
$gradient-add-bottom-hover	: #800c0c !default;

$gradient-quantity-top		: #434343 !default;
$gradient-quantity-bottom	: #030303 !default;

$gradient-quantity-color	: $white !default;

$pagination-color			: $content-color !default;
$pagination-color-hover		: $content-color !default;
$pagination-bg-color-hover	: $primary-color !default;

/* -- Fonts ----------------------------- */
$font-family				: Arial, Verdana, sans-serif !default;
$font-family-alt			: $font-family !default;

/* -- Répertoires ----------------------------- */
$img-telerik-url			: "../../../img/telerik" !default;
$img-plugins-url			: "../../../img/plugins" !default;

$img-url-old				: "../Images" !default;
$img-url					: "../img" !default;
$img-sprite-url				: "../img/sprite" !default;

/* -- Dimensions ----------------------------- */
/* Les valeurs sont en pixels */
$page-width					: 1200 !default;

$sidebar-width				: 245 !default;

$label-width				: 300 !default;
$input-width				: 250 !default;

/* Breakpoints */
$screen-small				: 800 !default;
$radgrid-medium				: 900 !default;


/* == @section Mixins  ==================== */
/* -- Fonctions et mixins tirés de ROCSSTI ----------------------------- */
/* @note ROCSSTI : une base CSS par Nicolas Hoffmann http://rocssti.net/
 * @note inspiré par http://www.knacss.com/
 * @note ROCSSTI est sous licence CC-BY : http://creativecommons.org/licenses/by/3.0/fr/
 */

// Ne pas modifier
$base-font					: 14 !default;
$l-h						: 1.5 !default;

// rythme vertical 
// valeurs possibles : 'double_top_single_bottom',  'single_top', 'single_bottom'
$vertical-rythm				: 'single_bottom' !default;

// Fonctions Sass : pas touche !
@function em($px, $base: $base-font) {
	@return calc(($px / $base) * 1em);
}

// calculées via http://soqr.fr/vertical-rhythm/ merci @goetter & @eQRoeil 

@function line-height ($font-size, $base-height, $base-font, $type-vr) {
	$coef: calc(1 / ($base-height * $base-font / $font-size));
	$height: calc($base-font * $base-height / $font-size) ;

	@return calc($height * $coef) ;
}

@function margin-em ($font-size, $base-height, $base-font, $type-vr) {
	$coef: calc(1 / ($base-height * $base-font / $font-size));
	$other_coef: calc($base-height * $base-font / $font-size);
	$height: calc($base-font * $base-height / $font-size) ;
	$margin: ($height * $coef );

	@if $type-vr == 'double_top_single_bottom' {
		@return ( ($margin * $other_coef)*1em 0 ( calc($margin/2) * $other_coef) *1em 0 );
	}
	@else if $type-vr == 'single_bottom' {
		@return ( 0 0 ( calc($margin/2) * $other_coef) *1em 0 );
	}
	@else if $type-vr == 'single_top' {
		@return ( ( calc($margin/2) * $other_coef) *1em 0 0 0 );
	}
}

@function margin-em-other ($font-size, $base-height, $base-font, $type-vr) {
	$coef: calc(1 / ($base-height * $base-font / $font-size));
	$height: calc($base-font * $base-height / $font-size) ;
	$margin: ($height * $coef );

	@return ( ($margin)*1em 0 );
}

// Mixins
@mixin flow-hr( $value, $type-vr: $vertical-rythm ) {
	font-size: em($value);
	line-height: line-height($value, $l-h, $base-font, $type-vr);
	margin: margin-em($value, $l-h, $base-font, $type-vr); 
}

@mixin flow-other( $value, $type-vr: $vertical-rythm ) {
	font-size: em($value);
	line-height: line-height($value, $l-h, $base-font, $type-vr);
	margin: margin-em-other($value, $l-h, $base-font, $type-vr);
}


/* -- Flexbox ----------------------------- */
/* @#note https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
@mixin flexbox() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin flexbox-direction() {
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-box-direction: normal;
	-moz-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

@mixin flex($values) {
	-webkit-box-flex: $values;
	-moz-box-flex:  $values;
	-webkit-flex:  $values;
	-ms-flex:  $values;
	flex:  $values;
}

@mixin order($val) {
	-webkit-box-ordinal-group: $val;  
	-moz-box-ordinal-group: $val;     
	-ms-flex-order: $val;     
	-webkit-order: $val;  
	order: $val;
}