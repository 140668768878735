@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-11-08
 * @note            Store / Results
 */


/* ----------------------------- */
/* Sommaire */
/* ----------------------------- */
/*
	== Filtres de recherche
	== Liste des produits
	  -- Layout
	  -- Détails du livre
	  -- Panier
*/


/* == @section Filtres de recherche ==================== */
.search-filters {
	margin-bottom: 2em;
}

.search-infos {
	@media screen and ( max-width: 430px ) {
		select {
			width: 100%;
		}
	}
}

.search-filters-fieldset {
	margin-top: 1em;
	border-top: 1px solid $fieldset;
}

.search-filters {
	fieldset {
		padding: 0;
		margin-top: 1em;
		border: none;
	}

	legend {
		font-weight: bold;
	}

	&-advanced {
		margin-top: .5em;
		
		> div {
			margin: .5em 0;

			&:last-child {
				text-align: right;
			}

			@media screen and ( min-width: 1061px ) {
				display: inline-block;
				margin: .25em 0;
				width: 48%;

				&:last-child {
					width: 100%;
				}
			}
		}

		label {
			display: inline-block;
			padding-right: .3em;
			width: 100px;

			&[for="pubDateBefore"],
			&[for="inputAdvSearchPriceMax"],
			&[for="inputFilterPriceMax"] {
				width: auto;
			}
		}

		@media screen and ( max-width: 430px ) {
			label {
				display: block;

				&[for="pubDateBefore"],
				&[for="inputAdvSearchPriceMax"],
				&[for="inputFilterPriceMax"] {
					display: inline-block;
				}
			}

			input[type="text"] {
				width: 100%;
			}

			input#inputAdvSearchFilterKeyword,
			input#inputFilterSearch {
				width: 95%;
				width: calc( 100% - 15px );
			}
		}

		@media screen and ( min-width: 430px ) {
			input[type="text"] {
				width: calc( 100% - 135px );
			}

			input#inputAdvSearchFilterKeyword {
				width: calc( 100% - 150px );
			}
		}

		/* Autres tailles de champs */
		// RadPicker
		input[type="text"].t-input {
			width: 100%;
		}

		// Prix CHF
		input[id="inputAdvSearchPriceMin"],
		input[id="inputAdvSearchPriceMax"],
		input[id="inputFilterPriceMin"],
		input[id="inputFilterPriceMax"] {
			width: 85px;
		}
	}
}

.search-filters {
	.js-expandmore {
		margin: 0;
		font-size: 1em;
		font-weight: normal;
		line-height: 1.5;
		text-align: center;
	}

	.expandmore__button {
		margin: .78em auto 0 auto;
	}

	.expandmore__to_expand legend {
		@extend .visually-hidden;
	}
}

.no-js .search-filters .js-expandmore {
	display: none;
}


/* == @section Liste des produits ==================== */
.result-table {
	colgroup,
	thead {
		display: none;
	}

	tbody tr {
		> td {
			padding: 1em .5em;
			border-bottom: 1px solid $fieldset;
		}

		&:first-child {
			> td {
				border-top: 1px solid $fieldset;
			}
		}
	}
}

/* -- @subsection Layout ---------- */
@media screen and ( max-width: 440px ) {
	.result-cover {
		margin-bottom: .5em;
		text-align: center;
	}
}

@media screen and ( min-width: 441px ) {
	.result-infos {
		display: table;
		table-layout: fixed;
		width: 100%;

		> div {
			display: table-cell;
			vertical-align: top;
		}
	}

	.result-cover {
		width: 125px;
	}
}

@media screen and ( max-width: 630px ) {}

@media screen and ( min-width: 631px ) {
	.result-item {
		display: table;
		table-layout: fixed;
		width: 100%;

		> div {
			display: table-cell;
			vertical-align: top;
		}
	}

	.result-basket { width: 180px; }
}


/* -- @subsection Détails du livre ---------- */
.result-details {
	h2 {
		margin-bottom: .5em;

		a {
			text-decoration: none;

			&:hover,
			&:focus {
				color: $fourth-color;
			}
		}

		span {
			display: block;
			font-weight: normal;
			font-size: 1em;
		}
	}

	.result-author span {
		font-weight: 500;

		&:not(:last-child):after {
			display: inline-block;
			content: ";";
		}
	}

	dt,
	dd {
		display: inline-block;
		vertical-align: top;
	}

	dt {
		width: 100px;
		font-weight: 500;
	}

	dd {
		margin-left: 0;
		width: calc( 100% - 105px );
	}

	dl a,
	.result-author a {
		color: $fourth-color;
		text-decoration: none;

		&:hover,
		&:focus {
			color: $content-color;
		}
	}
}


/* -- @subsection Panier ---------- */
.result-basket {
	.result-solde {
		color: $fourth-color;
	}

	.result-price {
		font-weight: bold;
		font-size: 1.1em;
	}

	.button-basket,
	.result-added {
		padding-top: .4em;
		padding-bottom: .4em;
		vertical-align: top;
	}

	.result-added {
		display: inline-block;
		margin-left: -4px; // white-space
		padding-right: .5em;
		padding-left: .5em;
		background: $gradient-quantity-top;
		background: -moz-linear-gradient(top, $gradient-quantity-top 0%, $gradient-quantity-bottom 100%);
		background: -webkit-linear-gradient(top,  $gradient-quantity-top 0%,$gradient-quantity-bottom 100%);
		background: linear-gradient(to bottom,  $gradient-quantity-top 0%,$gradient-quantity-bottom 100%);
		color: $gradient-quantity-color;
	}

	a.delete-basket {
		display: block;
		margin-top: .5em;
		color: $fourth-color;
		text-decoration: none;

		&:hover,
		&:focus {
			color: $content-color;
		}
	}
}