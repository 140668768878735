@charset "UTF-8";
/**
 * @site            Librairie La Liseuse
 * @author          Joana Henrique
 * @lastmodified    2024-06-04
 * @note            Forms
 */

/* == @section Selection list ==================== */
.liste-selection {
    .page-banner {
        h2 {
            font-size: 2.2em;
            color: #000;
            margin-bottom: .2em;
        }
        .banner-image {
            -webkit-mask-image: url(../../Images/author-background-1.svg);
            mask-image: url(../../Images/author-background-1.svg);
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            mask-size:55%;
            mask-position:center;
            img {
                min-height: 100px;
                max-width: unset;
            }
        }
    }
    .results-list {
        width:100%;
        .cartoucheRight {
            display:none;
        }
        #ResultsGrid {
            tbody {
                flex-direction:column;
                .cartouche {
                    align-items: center;
                }
                tr {
                    width:52%;
                    background-image:url(../../Images/product-background-1.svg);
                    background-color:transparent;
                    background-repeat:no-repeat;
                    background-position: center;
                    background-size:80%;
                    min-height:370px;
                    display:flex;
                    align-items: center;
                    &:not(:first-of-type) {
                        margin-top:-100px;
                    }
                    td {
                        padding:20px;
                    }
                    .imgCartouche {
                        transform: rotate(-6deg);
                        width:30%;
                    }

                    .texteCartouche {
                        width:70%;
                    }
                    &.t-alt {
                        background-image:url(../../Images/product-background-2.svg);
                        margin-left:auto;
                        background-size:85%;
                        min-height:380px;
                        .imgCartouche {
                            transform: rotate(6deg);
                        }
                    }
                }
            }
        }
    }
}

.categories-selection {
    .page-banner {
        flex-direction: column;
        text-align: center;
        h2 {
            font-size:2.2em;
            color:black;
            margin-bottom:0.2em;
        }
        h3 {
            font-size:1.4em;
            margin:0;
        }
    }

    #article {
        position: relative;
        overflow: hidden;
        &:before {
            content:url('../../Images/leaves-left.svg');
            position:absolute;
            left:-110px;
            bottom:20px;
            z-index:-1;
        }

        &:after {
            content:url('../../Images/leaves-right.svg');
            position:absolute;
            right:-140px;
            top:250px;
            z-index:1;
        }
    }
    #selection-categories-list {
        flex-wrap: wrap;
        margin-top: 60px;
        .selection-category {
            width:calc(33.33% - 20px);
            margin:10px;
            padding:0 0 40px;
            text-align: center;
            &.selection-id-68,
            &.selection-id-21,
            &.selection-id-22 {
                display: none;
            }
            img {
                min-height: 280px;
                width:300px;
                max-width: unset;
            }
            h3 {
                margin-top:20px;
                font-size:1.3em;
            }

            .selection-image {
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                mask-size:77%;
                mask-position:50% 75%;
            }

            &:nth-of-type(odd) {
                .selection-image {
                    -webkit-mask-image: url(../../Images/author-background-1.svg);
                    mask-image: url(../../Images/author-background-1.svg);
                }
            }

            &:nth-of-type(even) {
                .selection-image {
                    -webkit-mask-image: url(../../Images/author-background-2.svg);
                    mask-image: url(../../Images/author-background-2.svg);
                }
            }
        }
    }
}

@media screen and (max-width:860px) {
    .liste-selection {
        .page-banner {
            flex-direction: column;
            .banner-image {
                order:-1;
            }
            h2 {
                margin:10px auto 0;
                text-align: center;
            }
        }
        .results-list {
            #ResultsGrid {
                tbody {
                    tr {
                        width:80%;
                        min-height:330px;
                        &:not(:first-of-type) {
                            margin-top:0;
                        }
                    }
                }
            }
        }
    }

    .categories-selection {
        #selection-categories-list {
            .selection-category {
                width:calc(50% - 40px);
            }
        }
    }
}

@media screen and (max-width:680px) {
    .categories-selection {
        #selection-categories-list {
            .selection-category {
                width:calc(100% - 20px);
            }
        }
    }
}

@media screen and (max-width:480px) {
    .liste-selection {
        .results-list {
            #ResultsGrid {
                tbody {
                    tr {
                        width:100%;
                        background-position:top;
                        align-items: flex-start;
                        &:not(:first-of-type) {
                            margin-top:0;
                        }
                    }
                }
            }
        }
    }
}