@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-12
 * @note            Évènements
 */


/* ----------------------------- */
/* Sommaire */
/* ----------------------------- */
/*
	== Liste
	== Détail
*/

@media screen and ( max-width: 450px ) {
	.event-image	{
		margin-bottom: 1em;
	}
}

@media screen and ( min-width: 451px ) {
	.events-list,
	.event-detail {
		display: table;
		table-layout: fixed;
		width: 100%;

		> div {
			display: table-cell;
			vertical-align: top;
		}
	}

	.event-image	{ width: 25%; }
	.event-content	{ width: 75%; }

	.event-image	{
		padding-right: 2em;
	}
}

.event-link {
	display: block;
	margin-top: 1em;

	&:empty {
		display: none;
	}
}

.event-date {
	&:not(:empty) {
		margin-top: 1em;
	}

	span {
		display: block;
	}
}

.event-content {
	.event-time {
		color: $grey-medium;
	}
}

/* == @section Liste ==================== */
.events-list {
	margin-bottom: 1em;
	padding-bottom: 1em;
	border-bottom: 1px solid $form-border;

	h2 {
		a,
		a:visited,
		a:active {
			color: $fourth-color;
			text-decoration: none;
		}

		a:hover,
		a:focus {
			text-decoration: underline;
		}
	}
}

.event-detail-link {
	color: $fourth-color;

	&:before {
		display: inline-block;
		margin-right: .25em;
		content: "\203A";
	}

	a,
	a:visited,
	a:active {
		color: $fourth-color;
		text-decoration: none;
	}

	a:hover,
	a:focus {
		text-decoration: underline;
	}
}

/* == @section Détail ==================== */
@media screen and ( min-width: 451px ) {
	.event-detail {
		.event-image	{ width: 30%; }
		.event-content	{ width: 70%; }
	}
}

.event-header {
	.event-time + span:not(:empty):before {
		display: inline-block;
		content: " - ";
	}
}

.event-detail {
	.slick-slide {
		padding: 0 5px;

		img {
			max-height: 200px;
		}
	}
}