@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-12
 * @note            Basket
 */

.basket-table {
	a,
	a:visited,
	a:active {
		color: $fourth-color;
		font-weight: bold;
		font-size: 1.1em;
		text-decoration: none;
	}

	a:hover,
	a:focus {
		color: $content-color;
	}

	input[type="text"] {
		width: 100%;
	}

	.basket-format,
	del {
		color: $grey-medium;
	}

	.basket-format {
		font-size: smaller;
	}

	.basket-reduction {
		color: $fourth-color;
	}

	tfoot {
		font-weight: bold;

		> tr {
			> th,
			> td {
				border-top: 1px solid $grey-medium;
			}

			> th:first-child {
				text-align: right;
			}
		}

	}

	@media screen and ( max-width: $radgrid-medium+px ) {
		th {
			display: none;
		}

		tbody {
			tr {
				position: relative;
				display: block;
			}

			td {
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(5) {
					display: block;
				}

				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5) {
					display: inline-block;
					margin-right: -4px;
					vertical-align: top;
				}

				&:nth-child(3) { width: 110px; }
				&:nth-child(4) { width: 70px; }
				&:nth-child(5) { width: calc( 100% - 180px ); text-align: right; }

				&:nth-child(6) {
					position: absolute;
					top: 0;
					right: 0;
					display: block;
				}

				// Contenu sur plusieurs lignes
				&[id*="row-price"] > del,
				&[id*="row-price"] > span,
				> a,
				> span:not(.basket-reduction) {
					display: block;
				}

				// Affichage :before
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5) {
					&:before {
						display: block;
					}
				}
			}
		}

		tfoot {
			td:nth-child(2) {
				text-align: right;

				&:before {
					margin-right: 10px;
				}
			}

			td:nth-child(3) { display: none; }
		}

		tbody,
		tfoot {
			td {
				&:before {
					content: attr(data-header);
					font-weight: bold;
				}
			}
		}
	}

	@media screen and ( min-width: ( $radgrid-medium + 1)+px ) {
		tbody {
			td {
				> a,
				> span:not(.basket-reduction) {
					display: block;
				}
			}
		}
	}
}