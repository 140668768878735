@charset "UTF-8";
/**
 * @site			Librairie L'intégrale
 * @author			Luc Poupard
 * @lastmodified	2017-10-13
 * @note            jQuery.mmenu 5.6.3
 */

.mm-menu {
	background: $grey-dark;
	border-right: 2px solid $primary-color;
}

.mm-menu .mm-divider,
.mm-menu .mm-listview>li.mm-opened.mm-vertical>.mm-panel,
.mm-menu .mm-listview>li.mm-opened.mm-vertical>a.mm-next,
.mm-menu.mm-vertical .mm-listview>li.mm-opened>.mm-panel,
.mm-menu.mm-vertical .mm-listview>li.mm-opened>a.mm-next {
	background-color: $grey-medium;
}

.mm-listview > li.cat-title {
	background-color: $base-bg-color;
	border-top: 1px solid $primary-color;
	text-transform: uppercase;
	font-weight: bold;
}

.level1 {
	background-color: $base-bg-color;

	&:last-child {
		border-bottom: 1px solid $primary-color;		
	}
}

.level1.selectedCategorie,
.level2.selectedSubCategorie {
	background-color: $primary-color;

	> a {
		color: $black;
	}
}

.mm-panels>.mm-panel:not(#mm-0) {
	background-color: $base-bg-color;
}