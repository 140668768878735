@charset "UTF-8";
/**
 * @site            Librairie La Liseuse
 * @author          Joana Henrique
 * @lastmodified    2024-05-21
 * @note            Layout
 */

 /* == @section Global ==================== */

body {
	font-size:18px;
}

.section {
    padding:100px 0;
    .section-title {
        text-align: center;
        font-size:2em;
        line-height:1;
    }

    h2 {
        font-size:2em;
    }
}

.inner-container {
    width:90%;
    max-width:1220px;
    margin:0 auto;
}

.page-banner {
    background-color:$secondary-color-light;
    min-height:300px;
    display:flex;
    align-items: center;
    justify-content: center;
}

ul {
    list-style-type: none;
    padding-left:20px;
}

.hidden {
    display: none !important;
}

.flex {
    display:flex;
}

a {
    text-decoration: none;
}

h1,
h2,
h3 {
    font-family: "Marcellus", serif;
}

h1 {
    line-height:1.3;
}

h3 {
    line-height:1.2;
}

.outlinedButton {
    background-color:$base-bg-color;
    border:2px solid $secondary-color;
    color:$secondary-color;
    &:hover {
        background-color:$base-bg-color;
        border:2px solid $primary-color;
        color:$primary-color;
    }
}


/* == @section Header - Main Menu ==================== */
#header {
    background-color:$primary-color;
    padding:1em 0;
    position: relative;
    z-index:99;
    .inner-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width:1660px;
        flex-wrap: wrap;
        width:97%;
    }

    .logo-image {
        max-width:150px;
        img {
            height:40px;
        }
    }

    #nav {
        background-color:transparent;
        .mainNav {
            display:flex;
            padding:0;
            flex-wrap: wrap;
            justify-content: center;
            li {
                padding:10px 15px;
                text-align: center;
                a,
                span {
                    color:$base-bg-color;
                    opacity:1;
                    transition: opacity .2s;
                    &:hover {
                        opacity:0.6;
                    }
                }
            }
        }

        .sub-box {
            position: relative;
            cursor: pointer;
            h2 {
                display:none;
            }
            .submenu {
                background-color:$base-bg-color;
                padding:10px 0;
                position: absolute;
                top:100%;
                z-index:1;
                width:max-content;
                opacity:0;
                transform:scaleY(0);
                transform-origin:center top;
                transition:transform 0.3s, opacity 0.3s;
                ul {
                    padding:0;
                    text-align: left;
                }
                .level1 {
                    background-color:transparent;
                    border:none;
                    font-size:16px;
                    font-weight:600;
                    text-align: left;
                    padding:0;
                    a {
                        color:$primary-color;
                        display:block;
                    }
                    & > a {
                        padding:10px 20px;
                    }
                    &:hover {
                        background-color:$secondary-color-light;
                        a {
                            opacity:1;
                        }
                    }
                    &.selectedCategorie {
                        padding-bottom:0;
                        ul {
                            padding:0;
                        }
                    }
                }   
                .level2 {
                    text-align: left;
                    padding:0;

                    & > a {
                        padding:0 20px 15px 45px;
                    }
                } 
            }
            &:not(.submenu-close):hover,
            &.submenu-open {
                .submenu {
                    opacity:1;
                    transform:scaleY(1);
                    pointer-events:auto;
                }
            }
        }
    }

    .header-items {
        display: flex;
        align-items: center;
        margin:0 auto;
    }

    .user-icons {
        position: relative;
        label {
            cursor: pointer;
            margin-left:1.35em;
            width:30px;
            height:30px;
            img {
                width:30px;
                height:30px;
            }
            overflow:hidden;
        }
    }

    .user-info-content {
        opacity:0;
        transform:scaleY(0%);
        transform-origin: 0 0;
        transition:all 0.5s;
        height:0;
        width:max-content;
        position:absolute;
        top:125%;
        right:0;
        -webkit-box-shadow: $shadow; 
        box-shadow:$shadow;
        color:$primary-color;
        z-index:25;
        background-color:$base-bg-color;
        padding:.75em;
        .identifiedAs {
            text-align: left;
            font-size:.8em;
            font-weight:600;
        }
      
        .box {
            margin: 0;
        }

        #account {
            ul {
                padding:0 0 8px;
                margin:0 0 8px;
                text-align: left;
                font-size:0.8em;
                border-bottom:1px solid $border-color;
                li {
                    display:inline-block;
                    &:not(:last-child):after {
                        content:" | ";
                        color:$secondary-color;
                    }
                    a {
                        color:$secondary-color;
                        font-weight:500;
                    }
                }
            }
            .form-row {
                display:flex;
                align-items: center;
                input[type="text"],
                input[type="password"],
                input[type="email"] {
                    margin-right:0.8em;
                    width:150px;
                }
            }
        }

        .btnSubmitLogin {
            width:40px;
            height:40px;
            padding:10px;
            background-color:$secondary-color;
            border-radius:5px;
        }

        &#header-search {
            .search-advanced {
                display:block;
                padding-top:12px;
                font-size:15px;
                color:$secondary-color;
            }

            button {
                width:40px;
                height:40px;
                padding:0;
                img {
                    width:20px;
                    height:20px;
                }
            }
        }
    }
    .user-info-input {
        &:checked + label {
            & + .user-info-content {
                opacity: 1;
                -webkit-transform: scaleY(100%);
                transform: scaleY(100%);
                height: auto;
            }
        }
    }

    .panier {
        display:flex;
        align-items: center;
        h5 {
            margin:0 10px 0 0;
        }
        * {
            color:$primary-color;
            font-size:14px;
        }
    }

    .menu-button-mobile {
        display: none;
        margin-right:auto;
        color:$base-bg-color;
        font-weight:600;
        background-color:$secondary-color;
        border-radius:50px;
        padding:10px 20px;
        font-size:0.8em;
        img {
            margin-right:10px;
        }
    }

    @media screen and (max-width:1340px) {
        .header-items {
            margin:0;
        }

        .menu-button-mobile {
            display:flex;
        }

        #account {
            display:initial;
            padding:0;
        }
    }

    @media screen and (max-width:480px) {
        .header-items {
            width:100%;
        }
        .inner-container {
            flex-direction: column;
            justify-content:center;
            .logo-image {
                margin-bottom:20px;
            }
        }
        .user-info-content {
            right:-13px;
        }
    }
}

#menu-mobile {
    background-color:$secondary-color;
    .mm-panel {
        padding-top:0;

        .mm-next {
            &:before {
                border:none;
            }
        }
    }
    .mm-title {
        display: none;
    }
    a,
    span {
        font-weight:600;
        font-size:16px;
        padding:15px 15px 15px 20px;
    }

    .submenu {
        padding:40px 0;
        font-weight:600;
        font-size:16px;
    }
    .level1 {
        background-color:transparent;
        &.selectedCategorie {
            background-color:$primary-color;
            a {
                color:$base-bg-color;
            }
        }
        &:last-child {
            border:none;
        }
    }
    .mm-panels>.mm-panel:not(#mm-0) {
        background-color: $secondary-color;
    }
}


/* == @section Footer ==================== */
#footer {
    background-color:$primary-color;
    padding:45px 0 30px;
    position: relative;
    
    .inner-container {
        display:flex;
        flex-wrap: wrap;
        .footer-col {
            width:25%;
            text-align: left;
            color:$base-bg-color;
            &.footer-col-1 {
                color:$secondary-color;
                a {
                    color:$secondary-color;
                }
            }
            h3 {
                font-size:20px;
            }
            ul {
                padding:0;
                font-size:15px;   
                li {
                    padding:4px 0;
                    a {
                        color:$base-bg-color;
                    }
                }
            }
        }
    } 

    .socialNetworks {
        margin-top:5px;
        display: flex;
        a {
            text-decoration: none;
        }
        img {
            margin-right:10px;
            width:20px;
            height:20px;
        }
    }

    .footer-image {
        position: absolute;
        right:20px;
        bottom:10px;
    }

    @media screen and (max-width:1000px) {
        .inner-container {
            .footer-col {
                width:50%;
            }
        }
    }
    @media screen and (max-width:600px) {
        .inner-container {
            .footer-col {
                width:100%;
                text-align: center;
                h3 {
                    &.empty {
                        display: none;
                    }
                }
            }
        }
        .socialNetworks {
            justify-content: center;
        }
        .footer-image {
            display:none;
        }
    }
}
.copyright {
    background-color:$secondary-color;
    text-align: center;
    padding:13px;
    color:$base-color;
    font-size:13px;
    font-weight:600;
}

/* == @section Rotator ==================== */
.bx-wrapper {
    .bx-pager,
    .bx-pager.bx-default-pager {
        text-align: center;
        a {
            width:13px;
            height:13px;
            border-radius:50px;
            border:2px solid $primary-color;
            background-color:transparent;
            &.active {
                background-color:$primary-color;
            }
        }
    }
}

.slick-dots {
    text-align: center;
    margin:0;
    padding:0;
    li {
        display:inline;
    }
    button {
        width:13px;
        height:13px;
        border-radius:50px;
        border:2px solid $primary-color;
        background-color:transparent;
        padding:0;
        overflow: hidden;
        color:transparent;
        margin:0 3px;
    }
    .slick-active {
        button {
            background-color:$primary-color;
        }
    }
}
    
#main-rotator {
    background-color:$primary-color;
    padding:0 0 40px;
    height:calc(100vh - 115px);
    display:flex;
    align-items: center;
    .inner-container {
        max-width:1300px;
    }

    .slick-slide {
        display:flex;
        align-items: center;
        color:$base-bg-color;
        text-align: left;
    }
    
    h3 {
        font-size:40px;
        margin-bottom:0.1em;
    }
    h4 {
        font-size:32px;
        font-weight:600;
    }
    h5 {
        font-size:19px;
        font-weight:300;
        line-height:1.4;
    }

    .textRotator {
        width:35%;
        padding:20px;
    }

    .imageRotator {
        width:65%;
    
        .imageMask {
            -webkit-mask-image: url(../../Images/banner-mask.svg);
            mask-image: url(../../Images/banner-mask.svg);
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            mask-size:72%;
            mask-position:center;
            text-align: center;
        }
        img {
            max-width:100%;
            width:unset;
            min-height:540px;
        }
    }

    @media screen and (max-width:900px) {
        height:unset;
        .slick-slide {
            flex-direction: column;
            .textRotator,
            .imageRotator {
                width:100%;

                .imageMask {
                    height:40vh;
                    mask-position:bottom center;
                }
                img {
                    min-height:unset;
                }
            }
        }
    }
}

.smallCarousel,
.bigCarousel {
    .bx-wrapper {
        width:unset;

        .bx-pager {
            bottom:-40px;
        }
    }
}

#news-rotator-1,
#news-rotator-2,
#news-rotator-3 {
    .section-title {
        text-align: left;
    }

    .bx-pager {
        bottom:-15px;
    }
    .more-events {
        margin-top:1.5rem;
    }
}

/* == @section Homepage ==================== */
.home {
    #quote {
        background-color:$primary-color;
        padding:40px 0;

        * {
            color:$secondary-color!important;
            font-family: "Marcellus", serif !important;
            font-weight:normal !important;
            font-size:2.3rem !important;
        }
        h3 {
            display:none;
        }
    }
    .news {
        padding:0;
        .newsBox {
            margin:0 auto;
            max-width:500px;
        }
    }

    #main-selection {
        position:relative;
        overflow:hidden;
        .smallCarousel {
            margin-top:3rem;
            
            .product-image {
                width: 90%;
                height: 270px;            
                display:flex;
                align-items: center;
                justify-content: flex-start;
            }
            img {
                margin:0;
                max-width:100%;
                height:100%;
                max-height:320px;
            }

            @media screen and (max-width:600px) {
                .product-details {
                    text-align: center;
                }
                .product-image {
                    justify-content: center;
                }
            }
        }

        &:before {
            content:url('../../Images/leaves-left.svg');
            position:absolute;
            left:-110px;
            bottom:30px;
            z-index:-1;
        }

        &:after {
            content:url('../../Images/leaves-right.svg');
            position:absolute;
            right:-140px;
            top:40px;
            z-index:-1;
        }
    }

    .events-preview {
        display:flex;
        .event-item {
            width:calc(50% - 10px);
            margin:0 5px;
            padding:10px 30px;
            background-repeat:no-repeat;
            background-size:85%;
            display: flex;
            min-height:335px;
            display: flex;
            align-items: center;
            &:first-of-type {
                background-image:url(../../Images/product-background-1.svg);
            }
            &:last-of-type {
                background-image:url(../../Images/product-background-2.svg);
            }
            p {
                margin:0;
            }

            .event-image {
                position:relative;
                width:30%;
                padding-right:1em;
                &:before {
                    content:url('../../Images/leaves-background-2.svg');
                    position:absolute;
                    left:-40px;
                    top:-60px;
                    z-index:0;
                }
                img {
                    position: relative;
                    z-index:1;
                }
            }

            .event-content {
                width:70%;
            }
        }

        @media screen and (max-width:900px) {
            flex-direction: column;
            .event-item {
                width:100%;
                background-size:100%;
                flex-direction: column;
                margin:1em 0;
                padding:2em 30px;
                align-items:center;
                min-height:230px;
                text-align: center;
                .event-image {
                    margin-bottom:10px;   
                    width:100%;
                    padding:0;
                }
                h3 {
                    margin-bottom:0;
                }
            }
        }
    }

    .more-events {
        text-align: center;
        margin-top:1em;
        font-weight:600;
        font-size:.84em;
        a {
            color:$primary-color;
            text-decoration: underline;
        }
    }

    #events {
        .event-type {
            font-size:0.85em;
            margin-bottom:0.4em;
        }

        .event-main-title {
            margin-bottom:0.2em;
        }

        .event-subtitle {
            font-family: Marcellus, serif;
            margin-bottom:0.2em;
        }
        .event-date {
            font-size:0.85em;
            font-weight:700;
        }
        p {
            margin-top:0.75em;
        }
    }
}

.liste-events {
    .section {
        position:relative;
        overflow: hidden;
        &:before {
            content:url('../../Images/leaves-left.svg');
            position:absolute;
            left:-110px;
            bottom:30px;
            z-index:-1;
        }

        &:after {
            content:url('../../Images/leaves-right.svg');
            position:absolute;
            right:-140px;
            top:60px;
            z-index:-1;
        }
    }

    .eventDate {
        font-weight:700;
    }

    .event-type {
        font-size:0.8em;
        margin-bottom:1em;
    }

    .event-main-title  {
        margin-bottom:0.5px;
    }

    .event-text {
        h3 {
            margin-bottom:0.35em;
        }
    }
}


.bigCarousel {
    .carousel-book-box:nth-of-type(odd) .carousel-book {
        background-image:url(../../Images/product-background-1.svg);
    }
    .carousel-book-box:nth-of-type(even) .carousel-book {
        background-image:url(../../Images/product-background-2.svg);
    }

    .carousel-book {
        display: flex;
        background-repeat:no-repeat;
        background-size:85%;
        align-items:center;
        min-height:360px;
        padding:0 20px 0 40px;
        .product-image {
            width:30%;
            position: relative;
            &:before {
                content:url('../../Images/leaves-background-1.svg');
                position:absolute;
                left:-40px;
                top:-40px;
                z-index:0;
            }
            img {
                width:100%;
                position:relative;
                z-index:1;
            }
        }
        .product-details {
            padding:0 0 0 20px;
            width:70%;
            text-align:left;
        } 
    }

    @media screen and (max-width:900px) {
        .carousel-book {
            background-size: auto 97%;
            background-position: center;
        }
    }

    @media screen and (max-width:600px) {
        .carousel-book-box {
            .carousel-book {
                padding:40px 0 0;
                background-size: auto 40%;
                background-position: center 50px;
                flex-direction: column;
            }
        }
        .carousel-book {
            .product-image {
                width:50%;
            }

            .product-details {
                padding:0 0 0 20px;
                width:100%;
                text-align:center;
            } 
            .addBasketContainer {
                justify-content: center;
            }
        }
        .slick-dots {
            margin-top:-20px;
        }
    }
}

.product-details {
    text-align: left;
    h3 {
        font-size:24px;
        margin-bottom:10px;
        line-height:1.15;
        letter-spacing:0.5px;
    }
   
    h4 {
        font-size:17px;
        margin:12px 0 10px;
    }

    h5 {
        font-size:16px;
        color:$primary-color;
        margin-bottom:4px;
    }
}

/* == @section Basket ==================== */
.basket-buttons {
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top:2em;
}

.basket-account {
    position:absolute;
    top:-10px;
    right:-10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:$white;
    background-color:$primary-color;
    height:20px;
    width:20px;
    border-radius:25px;
    border:2px solid $white;
    font-size: 12px;
    font-weight:600;

    .article-text {
        display:none;
    }
}

.QuantityInput {
    max-width:100%;
}

/* == @section la Librairie ==================== */
.la-librairie {
    .titrePage {
        display: none;
    }

    #article {
        position: relative;
        overflow: hidden;
        &:after {
            content: url(../../Images/leaves-right.svg);
            position: absolute;
            right: -140px;
            top: 250px;
            z-index: 1;
        }
        &:before {
            content: url(../../Images/leaves-left.svg);
            position: absolute;
            left: -110px;
            bottom: 20px;
            z-index: -1;
        }
    }

    .section {
        padding:0;
        > .inner-container {
            width:100%;
            max-width:unset;
            .page-banner {
                flex-direction: column;
                text-align: center;
                h2 {
                    font-size: 2.2em;
                    color: #000;
                    margin-bottom: .2em;
                }
                h3 {
                    font-size: 1.4em;
                    margin: 0;
                }
            }
        }
    }

    #selection-categories-list {
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .selection-image {
        
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: 77%;
        mask-size: 77%;
        -webkit-mask-position: 50% 75%;
        mask-position: 50% 75%;
    }

    .selection-category {
        width: calc(33.33% - 20px);
        margin: 10px;
        padding: 0 0 40px;
        text-align: center;
        h3 {
            margin-top: 20px;
            font-size: 1.3em;
        }
        &:nth-of-type(odd) {
            .selection-image {
                -webkit-mask-image: url(../../Images/author-background-1.svg);
                mask-image: url(../../Images/author-background-1.svg);
            }
        }

        &:nth-of-type(even) {
            .selection-image {
                -webkit-mask-image: url(../../Images/author-background-2.svg);
                mask-image: url(../../Images/author-background-2.svg);
            }
        } 

        img {
            min-height: 280px;
            width: 300px;
            max-width: unset;
        }
    }

    @media screen and (max-width: 860px) {
        .selection-category {
            width: calc(50% - 40px);
        }
    }

    @media screen and (max-width: 680px) {
        .selection-category {
            width: calc(100% - 20px);
        }
    }
}   

/* == @section Events ==================== */
.pageEvents {
    display: flex;
    flex-wrap: wrap;
    margin:-20px -20px 0;

    .event-item {
        width:calc(33% - 40px);
        margin:20px;
        p {
            margin:0.25em 0;
            a {
                color:$primary-color;
                font-size:14px;
                font-weight:700;
                text-decoration: underline;
            }
        }
        .eventImage {
            width:100%;
            margin-bottom:0.8em;
        }

        h5 {
            font-size:0.75em;
            color:$gray;
            margin-top:0.8em;
            font-weight:500;
        }
        h3 {
            font-size:1.25em;
            margin:0.5em auto 0.2em;
        }
        h4 {
            font-size:0.95em;
        }

        button {
            font-size:0.8em;
            margin-top:1em;
        }

        .eventContenu {
            text-align: center;
        } 
    }

    .event-details {
        margin-top:-60px;
        h3 {
            font-size:1.5em;
            margin-bottom:0.4em;
        }
        .eventContenu {
            display:flex;
            flex-wrap: wrap;
            margin-top:2em;
            .span4 {
                width:30%;
                margin-right:2em;
            }
            .span8 {
                width:70%;
            }
        }
        
        .bx-wrapper {
            margin:0;
            img {
                margin:unset;
            }
        }
    }


    @media screen and (max-width:800px) {
        margin:0;
        padding-top:10px;
        .event-item {
            width:100%;
            &:not(:last-of-type) {
                border-bottom:1px solid $border-color;
                padding-bottom:30px;
                margin-bottom:10px;
            }
        }
    }
}

.previous-events {
    margin-bottom:60px;
}

.eventDate {
    margin:0 0 5px;
    font-size:15px;
    color:$secondary-color;
}

.event-page-detail {
    .linkBack {
        a {
            color:$primary-color;
            font-size:14px;
            font-weight:700;
            text-decoration: underline;
        }
    }

    .eventGallery {
        margin-top:3em;
        padding-top:3em;
        border-top:1px solid $border-color;
        
        .more-images-title {
            font-size:2em;
        } 
    }

    .event-detail-item {
        margin-top:2em;
        display:flex;
    }
}


/* == @section Contact ==================== */
.contact-page {
    background-color:$secondary-color-light;

    .section-title {
        text-align: left;
    }

    .contactAdresse {
        width:calc(40% - 30px);
        margin:15px;
        > div {
            background-color:$base-bg-color;
            padding:30px;
        }
    }
    #myContactForm {
        background-color:$base-bg-color;
        padding:30px;
        width:calc(60% - 30px);
        margin:15px;
        div {
            margin-bottom:15px;
        }
        label {
            display:block;
            font-size:15px;
            font-weight:700;
            margin-bottom:5px;
        }
        input,
        textarea {
            width:100%;
        }
    }

    .flex {
        flex-wrap: wrap;
    }

    @media screen and (max-width:800px) {
        .contactAdresse {
            width:100%;
            margin:0 0 25px 0;
        }
        #myContactForm {
            width:100%;
            margin:0;
        }
    }
}

/* == @section Order History ==================== */
.order-history-page,
.advancedSearch {
    .t-datepicker {
        margin-right:1em;
    }
    .t-picker-wrap {
        .t-icon {
            margin-top:10px;
        }
    }
}

.advancedSearch {
    background-color:$secondary-color-light;
    .inner-container {
        background-color:$base-bg-color;
        padding:30px;
    }
}

tbody {
    tr {
        &:first-child {
            border:none;
        }
    }
}

#tableBasket {
    margin-top:3em;
    border-bottom:1px solid #e3e0e5;
    
    .tableauHeader {
        font-weight:700;
        font-size:.9em;
        .tableauQuantity {
            text-align: center;
        }
    }

    input[type=text] {
        width:100%;
    }

    .priceUnit,
    .priceCell {
        text-align: right;
    }

    .tableauEntree {
        td {
            font-size:.9em;
        }
        a {
            color:$primary-color;
            font-weight:700;
            font-size:1em;
        }
        .priceCell {
            font-weight:600;
        }

        .availabilityCell {
            font-style: italic;
        }

        .tableauAuteur {
            color:$primary-color;
            font-size:.95em;
        }

        .tableauFormat {
            font-size:.85em;
        }

        .tableauQuantity {
            text-align: center;
        }
    }
}

.fraisPort,
.tableauPricing {
    td {
        font-size:.9em;
        font-weight:600;
        text-align: right;
        margin-top:1em;
    }
}

.basket-page,
.validate-order-page {
    background-color:$secondary-color-light;
    .inner-container {
        background-color: $base-bg-color;
        padding:2em;
    }

    #emptyBasket {
        text-align: center;
    }
}

.validate-order-page {
    h3 {
        border-bottom:1px solid $border-color;
        padding-bottom:.5em;
    }
}

@media screen and (max-width:900px) {
    #myCheckoutForm {
        .checkout-addresses {
            display: flex;
            flex-wrap:wrap;
            margin:3em 0;
        }
        .checkoutAdresseFacturation,
        .checkoutAdresseLivraison {
            tr {
                border:none;
            }
        }
    }

    .basket-page,
    .validate-order-page {
        .inner-container {
            padding:1em;
        }
    }

    #tableBasket {
        margin:0 auto;
        position: relative;
        .tableauHeader {
            display:none;
        }
        tbody {
            display:block;
            tr {
                display:flex;
                flex-wrap: wrap;
                padding:8px 0;
                margin:8px 0;
            }
        }
        .tableauTitle {
            width:100%;
        } 
        .remove-link {
            position: absolute;
            right:0;
        }

        .fraisPort, 
        .tableauPricing {
            padding:0 0 5px;
            &:first-child {
                border:none;
            }
            td {
                width:100%;
                margin-top:0;
                padding:0 .5em;
            }
        }
    }
    

    .basket-buttons {
        margin-top:0;
        a {
            margin:10px auto;
            width:100%;
            text-align: center;
        }
    }
}



@media screen and (max-width:800px) {
    .section {
        padding:60px 0;
    }

    .event-page-detail {
        .event-detail-item {
            flex-direction: column;
        }

        .eventGallery {
            margin-top:4em;
        }
    }

    .liste-selection {
        .page-banner {
            .banner-image {
                img {
                    max-width:100%;
                }
            }
        }
    }

    #myCheckoutForm {
        .checkoutAdresseFacturation,
        .checkoutAdresseLivraison {
            width:100%;
        }
        .checkoutAdresseLivraison {
            margin-top:2em;
        }
        .priceCell {
            margin-left:auto;
        }
    }
}