@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2018-02-01
 * @note            Valider ma commande
 */


/* ----------------------------- */
/* Sommaire */
/* ----------------------------- */
/*
	== Panier
	== Adresses
	== Préférences
	== Conditions
*/

.checkout.error-message {
	> span[class="field-validation-error"] {
		display: block;
		margin-bottom: 1.5em;
		padding: .5em;
		border: 1px solid $error;
		background-color: rgba( $error, .075 );
	}
}

/* == @section Panier ==================== */
.checkout-table {
	a,
	a:visited,
	a:active {
		color: $fourth-color;
		font-weight: bold;
		font-size: 1.1em;
		text-decoration: none;
	}

	a:hover,
	a:focus {
		color: $content-color;
	}

	.checkout-format,
	del {
		color: $grey-medium;
	}

	.checkout-format {
		font-size: smaller;
	}

	.checkout-reduction {
		color: $fourth-color;
	}

	tfoot {
		> tr {
			> th,
			> td {
				border-top: 1px solid $fieldset;
			}

			&:first-child > th,
			&:first-child > td {
				border-top: 1px solid $grey-medium;
			}

			&:nth-child(2) > th,
			&:nth-child(2) > td {
				font-weight: normal;
			}

			> th:first-child {
				text-align: right;
			}
		}
	}

	@media screen and ( max-width: $radgrid-medium+px ) {
		th {
			display: none;
		}

		tbody {
			tr {
				position: relative;
			}

			td {
				&:nth-child(1) {
					display: block;
				}

				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					display: inline-block;
					margin-right: -4px;
					vertical-align: top;
				}

				&:nth-child(2) { width: 110px; }
				&:nth-child(3) { width: 70px; }
				&:nth-child(4) { width: calc( 100% - 180px ); text-align: right; }

				// Contenu sur plusieurs lignes
				> a,
				> span:not(.checkout-reduction) {
					display: block;
				}

				// Affichage :before
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					&:before {
						display: block;
					}
				}
			}
		}

		tfoot {
			td:nth-child(2) {
				text-align: right;

				&:before {
					margin-right: 10px;
				}
			}
		}

		tbody,
		tfoot {
			td {
				&:before {
					content: attr(data-header);
					font-weight: bold;
				}
			}
		}
	}

	@media screen and ( min-width: ( $radgrid-medium + 1)+px ) {
		tbody {
			td {
				> a,
				> span:not(.checkout-reduction) {
					display: block;
				}
			}
		}
	}
}


/* == @section Adresses ==================== */
.checkout-addresses {
	@media screen and ( min-width: 581px ) {
		display: table;
		width: 100%;

		> div {
			display: table-cell;
			vertical-align: top;
			width: 50%;

			&:nth-child(1) { padding-right: 1em; }
			&:nth-child(2) { padding-left: 1em; }
		}
	}

	h2 {
		margin-top: 2em;
		padding-bottom: .5em;
		border-bottom: 1px solid $form-border;
	}

	@media screen and ( min-width: 881px ) {
		label {
			width: 100px;
			text-align: left;
		}

		.no-label {
			padding-left: 100px;
		}
	}
}


/* == @section Préférences ==================== */
.checkout-preferences {
	margin-top: 3em;

	h2 {
		padding-bottom: .5em;
		border-bottom: 1px solid $form-border;
	}
}

.checkout-paymode {
	display: inline-block;
	vertical-align: top;
}

.checkout-paymode_paypal {
	a {
		text-decoration: none;
	}

	img[alt="Paypal"] {
		vertical-align: text-bottom;
	}

	span {
		font-size: .9em;
		font-style: italic;
	}
}

#paypalCheckout img {
	vertical-align: text-top;
}

/* == @section Conditions ==================== */
.checkout-conditions {
	margin: 1.5em 0 2em 0;
	padding-top: 1em;
	border-top: 1px solid $form-border;

	label[for="cgv"] {
		padding-left: .25em;
		width: auto;
		text-align: left;

		@media screen and ( max-width: 880px ) {
			display: inline;
		}
	}

	.laposte {
		display: inline-block;
		color: $grey-medium;

		img {
			vertical-align: middle;
		}
	}

	+ .right a {
		display: inline-block;
		margin-left: .5em;
	}
}