@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-11-06
 * @note            Telerik - Windows 7
 */

.t-widget,
.t-input {
	// border-color: #E4D9E8;
	// background-color: #fcfcfc;
}

// .t-grid-header
.t-header {
	// display:none;
	// /*ell*/
	// /*joa*/
	// visibility: hidden;
	// /*ell*/
	// /*joa*/	
	border-color: $grey-medium;
	// background: $primary-color;
	background: $white;
	color: $grey-medium;
}

[id*="OrderDetails"] .t-header {
	border-color: $grey-light;
	background: $grey-light;
	color: $white;

	a.t-link,
	a.t-link:visited,
	a.t-link:active {
		color: $white;
	}
}

// .result-table .t-header,
// .t-grid-header {
// 	display: none;
// }

.t-icon {
	background-image: url('#{$img-telerik-url}/Windows7/sprite.png');
}

.t-editor .t-tool-icon {
	background-image: url('#{$img-telerik-url}/Windows7/editor.png');
}

.t-loading,
.t-widget .t-loading {
	background: transparent url('#{$img-telerik-url}/Windows7/loading.gif') no-repeat 0 0;
}

.t-widget,
.t-link,
.t-popup {
	// color: #000;
}

.t-header,
.t-header .t-link {
	// visibility: hidden;
	/*ell*/
	/*joa*/	
	// color: #4c607a; /*lup*/
}

.t-header a.t-link,
.t-header a.t-link:visited,
.t-header a.t-link:active {
	color: $grey-medium;
}

.t-header .t-group,
.t-group .t-link {
	visibility: hidden;
	display: none;
	/*joa*/	
	color: #333;
}

.t-group,
.t-content,
.t-editable-area {
	border-color: #a5b3c5;
	background-color: #fcfcfc;
}

.t-colorpicker .t-arrow-down {
	border-color: #a5b3c5;
}

.t-separator {
	border-color: #fff;
	background-color: #c2cedb;
}

.t-alt {
	// background-color: #ebeff3;
}

.t-state-default {
	border-color: #a5b3c5;
}

.t-active-filter {
	background-color: #c3dcfa;
}

.t-state-hover,
.t-state-hover .t-link,
.t-header .t-state-hover,
.t-group .t-state-hover {
	// visibility: hidden; /*joa*/	
	color: #000;
}

.t-state-hover,
.t-state-hover:hover {
	// background-color: #d1e4fc;
	// border-color: #8daed5;
}

.t-state-active {
	background-color: #fff;
	border-color: #426682;
}

.t-state-selected {
	background-color: #deecfe;
}

.t-state-focused {
	background-color: #c4d1e0;
}

.t-state-error,
.t-widget.input-validation-error,
.t-widget .input-validation-error {
	border-color: #f00;
	background-color: #ffc0cb;
}

.t-button {
	color: #0d202b;
}

.t-button,
button.t-button.t-state-disabled:hover,
a.t-button.t-state-disabled:hover,
.t-state-disabled .t-button:hover {
	border-color: #c2cedb;
	background: #e7ecf5 url('#{$img-telerik-url}/Windows7/sprite2.png') repeat-x 0 -560px;
}

.t-button:hover {
	border-color: #9bc9ff;
	background-position: 0 -624px;
	background-color: #dde8f6;
	color: #0e3d4f;
}

.t-state-disabled,
.t-state-disabled:hover,
.t-state-disabled .t-link,
.t-state-disabled .t-button {
	color: #999;
}

.t-toolbar,
.t-grouping-header,
.t-widget .t-status {
	visibility: hidden;
	display: none;
	/*joa*/
	//border-color: #aec5d1; /*joa*/	
	background-color: #f1f5fb;
}

.t-panelbar .t-link {
	border-color: #a5b3c5;
}

.t-tabstrip .t-item {
	border-bottom-color: #426682;
}

.t-grid td {
	// border-color: #dae2e8; /*ell*/
}

.t-grouping-row td {
	background: #fff url('#{$img-telerik-url}/Windows7/sprite2.png') repeat-x 0 -48px;
}

.t-footer-template td {
	// background-color: #e2eaf4; /*joa*/
	// border-color: #aec5d1 #e2eaf4; /*joa*/
}

.t-group-footer td {
	// background-color: #e2eaf4; /*joa*/
	// border-color: #aec5d1; /*joa*/
}

.t-grid-footer,
.t-grid-footer-wrap table {
	// background-color: #e2eaf4; /*joa*/
	// border-color: #aec5d1; /*joa*/
}

.t-grouping-header .t-group-indicator {
	visibility: hidden;
	/*joa*/	
	color: #333;
	border-color: #ccc;
}

.t-grouping-dropclue {
	background: url('#{$img-telerik-url}/Windows7/sprite2.png') no-repeat -48px -288px;
}

.t-grouping-row .t-group-cell,
.t-grouping-row p {
	background: #fff;
}

.t-treeview .t-drop-clue {
	background-image: url('#{$img-telerik-url}/Windows7/sprite2.png');
	background-position: 0 -358px;
}

.t-treeview .t-state-selected {
	border-color: #8fb0d6;
}

.t-calendar th {
	border-color: #d4dbe5;
}

.t-other-month .t-link {
	color: #a7a7a7;
}

.t-editor-button .t-state-hover {
	border-color: #e5f0fd #8ba6c4 #8ba6c4 #e5f0fd;
	background-color: #d1e4fc;
}

.t-editor-button .t-state-active {
	border-color: #aec1d6 #e3ebf5 #e3ebf5 #aec1d6;
	background-color: #ccdbed;
}

.t-slider .t-draghandle {
	background-image: url('#{$img-telerik-url}/Windows7/sprite2.png');
	color: #4c607a;
}

.t-slider-track {
	border-color: #a6abc3;
}

.t-slider-horizontal .t-slider-track,
.t-slider-horizontal .t-slider-selection {
	background-image: url('#{$img-telerik-url}/Windows7/sprite2.png');
}

.t-slider-vertical .t-slider-track,
.t-slider-vertical .t-slider-selection {
	background-image: url('#{$img-telerik-url}/Windows7/sprite-vertical.png');
}

.t-slider-horizontal .t-tick {
	background-image: url('#{$img-telerik-url}/Windows7/slider-h.gif');
}

.t-slider-vertical .t-tick {
	background-image: url('#{$img-telerik-url}/Windows7/slider-v.gif');
}

.t-tooltip {
	background: #e7ecf5 0 -560px repeat-x url('#{$img-telerik-url}/Windows7/sprite2.png');
	color: #0e3d4f;
}

.t-callout {
	background-image: url('#{$img-telerik-url}/Windows7/sprite2.png');
}

.t-splitter {
	border-color: #dae2e8;
}

.t-splitbar-vertical,
.t-splitbar-horizontal {
	background-color: #f1f5fb;
	border-color: #dae2e8;
}

.t-splitbar-vertical,
.t-ghost-splitbar-vertical {
	background-image: url('#{$img-telerik-url}/Windows7/sprite2.png');
}

.t-splitbar-horizontal,
.t-ghost-splitbar-horizontal {
	background-image: url('#{$img-telerik-url}/Windows7/sprite-vertical.png');
}

.t-dropzone-active,
.t-upload-files {
	border-color: #c2cedb;
}

.t-dropzone-hovered,
.t-upload-files {
	background-color: #f1f5fb;
}

.t-dropzone em {
	color: #a7a7a7;
}

.t-progress,
.t-progress-status {
	background-image: url('#{$img-telerik-url}/Windows7/sprite2.png');
}

.t-progress {
	background-position: 0 -465px;
	border-color: #c2cedb #c2cedb;
}

.t-progress-status {
	visibility: hidden;
	/*joa*/	
	background-position: 0 -472px;
	border-color: #1db2ee transparent #3186db;
}

.t-imagebrowser .t-content {
	background: #f1f5fb;
}

.t-breadcrumbs .t-link,
.t-tiles-arrange,
.t-tiles-arrange a {
	color: #1e395b;
}

.t-breadcrumbs .t-first,
.t-breadcrumbs .t-first:hover,
.t-search-wrap label {
	color: #a7a7a7;
}

.t-tiles {
	border-color: #c2cedb;
	background: #fff;
}

.t-tile {
	border-color: #fff;
	background: 0 100px repeat-x url('#{$img-telerik-url}/Windows7/imagebrowser.png');
}

.t-tiles li.t-state-hover {
	border-color: #b8d6fb;
	background-position: 0 0;
}

.t-tiles li.t-state-selected {
	border-color: #7da2ce;
	background-position: 0 -100px;
}

.t-tile .t-folder {
	background-image: url('#{$img-telerik-url}/Windows7/imagebrowser.png');
}

.t-tile em,
.t-tile .t-filesize {
	color: #a7a7a7;
}

.t-tiles li.t-state-selected em,
.t-tiles li.t-state-selected .t-filesize {
	color: #7da2ce;
}

.t-tile-empty strong {
	color: #c2cedb;
}

// Ajouts CDI
.t-detail-row {
	background-color: $form-bg;

	> td {
		border-bottom: 1px solid $fieldset;

		&:first-child { border-left: 1px solid $fieldset; }
		&:last-child { border-right: 1px solid $fieldset; }
	}
}