@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-16
 * @note            Store / Details
 */


/* ----------------------------- */
/* Sommaire */
/* ----------------------------- */
/*
	== Liens de retour
	== Produit
	  -- Layout
	  -- Entête
	  -- Détails
	  -- Panier
	  -- Résumé
*/


/* == @section Liens de retour ==================== */
[class^="back-link"] {
	padding: .5em 0;
	font-size: small;

	span:before {
		display: inline-block;
		content: "\2039";
		margin-right: .5em;
	}

	a {
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

.back-link-top {
	margin-bottom: 1em;
	border-bottom: 1px solid $form-border;
}

.back-link-bottom {
	margin-top: 1em;
	border-top: 1px solid $form-border;
}


/* == @section Produit ==================== */
/* -- @subsection Layout ---------- */
@media screen and ( max-width: 630px ) {
	.book-cover {
		margin-bottom: .5em;
		text-align: center;

		img {
			max-width: 200px;
		}
	}
}

@media screen and ( min-width: 631px ) {
	.book {
		display: table;
		table-layout: fixed;
		width: 100%;

		> div {
			display: table-cell;
			vertical-align: top;
		}
	}

	.book-cover {
		width: 160px;
		padding-right: 30px;
	}
}

@media screen and ( min-width: 901px ) {
	.book-infos {
		display: table;
		table-layout: fixed;
		width: 100%;

		> div {
			display: table-cell;
			vertical-align: top;
		}
	}

	.book-basket {
		width: 180px;
	}
}


/* -- @subsection Entête ---------- */
.book-header {
	h1 {
		span {
			display: block;
			font-weight: normal;
			font-size: 1em;
		}
	}

	.book-author span {
		font-weight: 500;

		&:not(:last-child):after {
			display: inline-block;
			content: ";";
		}
	}

	.book-author a {
		color: $fourth-color;
		text-decoration: none;

		&:hover,
		&:focus {
			color: $content-color;
		}
	}
}

/* -- @subsection Détails ---------- */
.book-details {
	dt,
	dd {
		display: inline-block;
		vertical-align: top;
	}

	dt {
		width: 120px;
		font-weight: 500;
	}

	dd {
		margin-left: 0;
		width: calc( 100% - 125px );
	}

	dl a {
		color: $fourth-color;
		text-decoration: none;

		&:hover,
		&:focus {
			color: $content-color;
		}
	}
}


/* -- @subsection Panier ---------- */
.book-basket {
	.book-solde {
		color: $fourth-color;
	}

	.book-price {
		font-weight: bold;
		font-size: 1.1em;
	}

	.button-basket,
	.book-added {
		padding-top: .4em;
		padding-bottom: .4em;
		vertical-align: top;
	}

	.book-added {
		display: inline-block;
		margin-left: -4px; // white-space
		padding-right: .5em;
		padding-left: .5em;
		background: $gradient-quantity-top;
		background: -moz-linear-gradient(top, $gradient-quantity-top 0%, $gradient-quantity-bottom 100%);
		background: -webkit-linear-gradient(top,  $gradient-quantity-top 0%,$gradient-quantity-bottom 100%);
		background: linear-gradient(to bottom,  $gradient-quantity-top 0%,$gradient-quantity-bottom 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-quantity-top', endColorstr='$gradient-quantity-bottom',GradientType=0 );
		color: $gradient-quantity-color;
	}

	a.delete-basket {
		display: block;
		margin-top: .5em;
		color: $fourth-color;
		text-decoration: none;

		&:hover,
		&:focus {
			color: $content-color;
		}
	}
}


/* -- @subsection Résumé ---------- */
.book-summary {
	margin-top: 1.5em;
}