@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-12
 * @note            Basket
 */


/* ----------------------------- */
/* Sommaire */
/* ----------------------------- */
/*
	== Général
	== Résultats
*/


/* == @section Général ==================== */
table {
	table-layout: fixed;
	border-collapse: collapse;
	margin-bottom: 1em;
	width: 100%;
	max-width: 100%;
}

td,
th {
	padding: .25em .5em;
	vertical-align: middle;
}

thead th {
	background-color: $grey-medium;
	color: $white;
	font-weight: normal;
	text-align: left;
}

td,
tfoot th {
	padding: .5em;
}

tfoot {
	font-weight: bold;

	> tr > td {
		border-top: 1px solid $grey-medium;
	}
}

@media screen and ( max-width: $radgrid-medium+px ) {
	tbody {
		tr {
			&:not(:last-child) {
				border-bottom: 1px solid $fieldset;
			}

			&:first-child {
				border-top: 1px solid $grey-medium;
			}
		}
	}
}

@media screen and ( min-width: ( $radgrid-medium + 1)+px ) {
	tbody {
		tr:not(:last-child) > td {
			border-bottom: 1px solid $fieldset;
		}
	}
}