@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-17
 * @note            Index
 */

.gallery {
	margin-top: 2em;
	padding: 1em 1em 0 1em;
	list-style: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	border: 1px solid $fieldset;
	width: 100%;

	> li {
		margin-bottom: 1em;

		@media screen and ( min-width: 360px ) and ( max-width: 499px ) {
			width: 49%;

			&:not(:nth-child(2n)) {
				margin-right: 2%;
			}
		}

		@media screen and ( min-width: 500px ) and ( max-width: 599px ) {
			width: 31.5%;

			&:not(:nth-child(3n)) {
				margin-right: 2.75%;
			}
		}

		@media screen and ( min-width: 600px ) {
			width: 23.5%;

			&:not(:nth-child(4n)) {
				margin-right: 2%;
			}
		}
	}
}