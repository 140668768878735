@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-16
 * @note            Home
 */


/* ----------------------------- */
/* Sommaire */
/* ----------------------------- */
/*
	== News
	== Rotators
	  -- Small
	  -- Big
	  -- Promo
	== Bxslider
	== Tooltips
*/


/* == @section News ==================== */
.rotator-news {
	@media screen and ( min-width: ( $screen-small + 1 )+px ) {
		display: table;
		table-layout: fixed;
		width: 100%;

		> div {
			display: table-cell;
			width: 50%;
		}

		.news-box,
		.rotator-small [class*="infiniteCarousel"] {
			height: 160px;
		}
	}
}

.news {
	@media screen and ( min-width: ( $screen-small + 1 )+px ) {
		padding-left: 2%;
	}

	@media screen and ( max-width: $screen-small+px ) {
		display: block;
		margin-top: 1em;
	}

	h2 {
		margin: 0;
	}

	&-box {
		padding: 0 1em;
		overflow: auto;
		overflow-x: hidden;
	}
}

#main .news {
	a,
	a:visited,
	a:active {
		color: $primary-color;
		text-decoration: none;
	}

	a:hover,
	a:focus {
		text-decoration: underline;
	}
}


/* == @section Rotators ==================== */
.rotator {
	margin-top: 1em;
	
	h2 {
		margin: 0;
	}

	[class*="infiniteCarousel"],
	[class*="carouselBigImageText"] {
		position: relative;
		padding: 1em 2em;
	}
}

/* -- @subsection Small ---------- */
.rotator-small {
	@media screen and ( min-width: ( $screen-small + 1 )+px ) {
		padding-right: 2%;
	}

	@media screen and ( max-width: $screen-small+px ) {
		display: block;
		width: 100%; // added 2016.08.16 for iOS5
	}
}


/* -- @subsection Big ---------- */
.rotator-big {
	display: block;
	width: 100%; // added 2016.08.16 for iOS5
	clear: both;
}


/* -- @subsection Promo ---------- */
.rotator-promo {
	display: block;
	margin-bottom: 1em;
	width: 100%;
	clear: both;

	h3,
	p {
		margin: 0;
	}

	&_slide {
		position: relative;

		&-img {
			text-align: left;

			@media screen and ( min-width: 551px ) {
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;
				padding: .25em .5em .5em .5em;
				background-color: rgba( $content-bg-color, .75 );
			}
		}
	}
}


/* == @section Tooltips ==================== */
.tooltipTitle {
	margin-bottom: .25em;
	font-weight: 500;
	line-height: 1.1;

	a,
	a:visited,
	a:active {
		color: $fourth-color;
		text-decoration: none;
	}

	a:hover,
	a:focus {
		color: $content-color;
	}
}

.tooltipAvail {
	font-size: smaller;
}

.tooltipPrice {
	font-weight: bold;
}

.tooltip-basket {
	display: table;
	width: 100%;

	> div {
		display: table-cell;
		vertical-align: middle;

		&:nth-child(1) {
			width: 30%;
		}

		&:nth-child(2) {
			text-align: right;
		}
	}
}

.tooltip-basket .button-basket,
.tooltip-added {
	padding-top: .4em;
	padding-bottom: .4em;
}

.tooltip-added {
	display: inline-block;
	margin-left: -4px; // white-space
	padding-right: .5em;
	padding-left: .5em;
	background: $gradient-quantity-top;
	background: -moz-linear-gradient(top, $gradient-quantity-top 0%, $gradient-quantity-bottom 100%);
	background: -webkit-linear-gradient(top,  $gradient-quantity-top 0%,$gradient-quantity-bottom 100%);
	background: linear-gradient(to bottom,  $gradient-quantity-top 0%,$gradient-quantity-bottom 100%);
	color: $gradient-quantity-color;
	text-decoration: none;
}