@charset "UTF-8";
/**
 * @site            Librairie La Liseuse
 * @author          Joana Henrique
 * @lastmodified    2024-05-21
 * @note            Forms
 */

/* == @section Results list ==================== */
#results-page {
    max-width:1380px;
    margin-top:60px;
    flex-wrap: wrap;
}

.filAriane {
    font-size:15px;
    color:$primary-color;
    font-weight:700;
    letter-spacing: 0.2px;
    a {
        text-decoration: underline;
        color:$secondary-color;
    }
}

.search-form {
    background-color:$secondary-color-light;
    padding:20px 0;
    width:calc(30% - 30px);
    margin:15px;
    padding:25px 20px;
    height:fit-content;
    ul {
        margin:0;
        padding-left:35px;
        list-style-type:circle;
        li {
            background-color:transparent;
            border:none!important;
            margin-top:5px;
            color:$primary-color;
            a {
                color:$primary-color;
                font-size:16px;
                font-weight:600;
                transition:all .2s;

                &:hover {
                    color:$base-color;
                }
            }
        }
    }

    .level1.selectedCategorie, 
    .level2.selectedSubCategorie {
        background-color:transparent;
    }
    .level1 {
        ul {
            padding-left:30px;
        }
        &:not(.selectedCategorie) {
            > ul {
                display:none;
            }
        }
    }
    .level2 {
        font-size:15px;
        a {
            font-size:15px;
        }
    }
    

    #search {
        padding:0 0 20px;
        form {
            width:100%;
            margin-bottom:10px;
        }
        select {
            width:100%;
            font-size:15px;
            cursor: pointer;
        }

        .champRecherche {
            display:flex;
            align-items: center;
            input {
                width:85%;
                margin-right:5px;
                font-size:15px;
            }
            button {
                width:35px;
                height:35px;
                padding:0;
                img {
                    width:17px;
                    height:17px;
                }
            }
        }
    }

    .advancedSearch {
        font-size:14px;
        font-weight:600;
        color:$primary-color;
        a {
            color:$primary-color;
            text-decoration: underline;
        }
    }

    .navigCategories {
        h2 {
            margin-bottom:0.5em;
        }
    }
}

.results-list {
    width:calc(70% - 30px);
    margin:15px;
    #resultsForm {
        margin: 0 0;
        padding:10px 0;
        .infos {
            display:flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom:1em;
            font-size:15px;
            color:$gray;
            border-bottom:1px solid $border-color;
            padding-bottom:1em;
        }

        .formAffiner {
            display:flex;
            flex-wrap: wrap;
            margin:-10px;
            padding-top:1.1em;
            display:none;
            .span6 {
                width:calc(50% - 20px);
                margin:10px;
                
                input[type=text] {
                    width:95%;
                }
                .inline {
                    display:flex;
                    align-items: center;
                    font-size:14px;
                    input[type=text] {
                        width:140px;
                        margin-right:15px;
                    }
                }
            }
        }

        .zoneAffiner {
            label {
                display:block;
                font-weight:700;
                margin-bottom:5px;
            }

            .advanced-button {
                cursor: pointer;
                color:$primary-color;
                span {
                    text-decoration: underline;
                    font-weight:600;
                    font-size:14px;
                }
            }

            #advanced-filter {
                &:checked {
                    & + .formAffiner {
                       display:flex;
                    }
                }
            }

            .search-keywords {
                margin:0 0 15px;
            }
        }

       
        .btnSubmitAffiner {
            margin:1em 0 1em auto;
        }
    } 
    
    .titreRecherche {
        text-transform: capitalize;
    }
    
    #ResultsGrid {
        tbody {
            display:flex;
            flex-wrap:wrap;
            justify-content: flex-start;
            
            tr {
                width:100%;
                border:none;
                &.t-alt {
                    background-color:lighten($secondary-color-light, 5);
                }
                td {
                    padding:35px;
                    border:none;
                }
            }
    
            .cartouche {
                display:flex;
                gap:30px;
                .imgCartouche {
                    width:20%;
                    img {
                        width:100%;
                    }
                }
                .texteCartouche {
                    width:80%;
                    .textBold {
                        padding-right:5px;
                    }
    
                    .price {
                        font-size:1.2em;
                        font-weight:600;
                        margin:0 0 10px;
                    }

                    .disponibility,
                    .inStock,
                    .NoDisponibility {
                        margin-bottom:0;
                        font-size: 15px;
                        font-weight: 600;
                        color:$primary-color;
                    }

                    .inStock {
                        color:green;
                    }

                    .NoDisponibility {
                        color:#df0000;
                    }
    
                    .auteurBook {
                        margin-bottom:.5em;
                        font-family:'Open Sans';
                        font-weight:600;
                        font-size:0.95em;
                        a {
                            color:$primary-color;
                        }
                    }
                }
            }
        }
    
        .infoBook {
            tbody {
                margin:0;
                tr {
                    width:100%;
                    margin:0;
                    td {
                        border:none;
                        padding:2px 0;
                        font-size:16px;
                        &.textBold {
                            font-weight:700;
                        }
                    }
                }
            }
        }
    }
    
}

.addBasketContainer {
    display:flex;
    align-items: center;
    .nbrExemplaires {
        margin:0 10px;
    }
    .lienSupprimer {
        color:$warning;
        text-decoration: underline;
    }

    .btnAddBasket {
        display:flex;
        justify-content: flex-start;
        img {
            width:25px;
            height:25px;
            margin-right:15px;
        }
    }
}
.detailContent {
    margin-bottom:60px;
    .section {
        padding-bottom:60px;
    }
    .book-preview {
        background-color:$secondary-color-light;
        .inner-container {
            padding:10px 0 65px;
        }

        .bookCover {
            img {
                width:100%;
            }
        }

        .auteurBookDetail {
            a {
                color:$primary-color;
            }
        }
    }

    .book-details {
        position: relative;
        overflow-x: hidden;
        &:before {
            content:url('../../Images/leaves-left.svg');
            position:absolute;
            left:-110px;
            bottom:30px;
            z-index:-1;
        }

        &:after {
            content:url('../../Images/leaves-right.svg');
            position:absolute;
            right:-140px;
            top:-20px;
            z-index:10;
        }
    }

    .linkBack {
        a {
            color:$primary-color;
            text-decoration: underline;
            font-weight:600;
            font-size:15px;
            img {
                margin-right:0.4em;
            }
        }
    }
    .flex {
        margin-top:40px;
        gap:20px;
        .titleZone {
            width:70%;
            .titreBookDetail {
                font-size:1.5em;
            }
        }

        .pricing {
            font-size:1.4em;
            font-weight:600;
            margin-bottom:20px;
            .price {
                margin:6px 0;
            }
        }

        .availability {
            p {
                margin:35px 0 5px;
            }
            .disponibility,
            .inStock,
            .NoDisponibility {
                font-size: 15px;
                font-weight: 600;
                color:$primary-color;
            }

            .inStock {
                color:green;
            }

            .NoDisponibility {
                color:#df0000;
            }
        }
        .bookCover {
            width:30%;
        }
    }

    .section {
        h3 {
            text-align: left;
        }
    }

    .infosZone {
        background-color:$secondary-color-light;
        margin-bottom:30px;
        margin-top:15px;
        a {
            color:$primary-color;
            &:hover {
                text-decoration: underline;
            }
        }
        tbody {
            display:flex;
            flex-wrap:wrap;
            gap:4px;
            padding:20px 25px;
            tr {
                width:33%;
                padding:10px;
                td {
                    border:none;
                    padding:5px 0;
                    display:block;
                    font-weight:700;
                    padding:0;
                    color:black;
                    &.labelBookDetail {
                        color:$gray;
                        font-weight:500;
                        font-size:15px;
                        padding-bottom:3px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 900px) {
        .infosZone {
            tbody {
                gap:0;
                tr {
                    width:50%;
                    border:none;
                    td {
                        border:none;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 680px) {
        .infosZone {
            tbody {
                tr {
                    width:100%;
                }
            }
        }
    }
}

.advancedSearch {
    .section-title { 
        img {
            width:30px;
            height:30px;
            margin-right:10px;
        }
    }

    
    form {
        .advanced-flex {
            display:flex;
            flex-wrap: wrap;
            margin:0 -15px;
            padding-top:2em;
            > div {
                width:calc(50% - 30px);
                margin:10px 15px;
            }
        }
        
        label {
            display:block;
            font-size: 15px;
            color: $gray;
        }
        select,
        input[type=text] {
            width:100%;
        }

        input[type=submit] {
            margin-top:2em;
        }

        .inline {
            display: flex;
            align-items: center;
            font-size: 14px;
            input[type=text] {
                margin:0 0.4em;
            }
        }
    }
}

.t-grid.t-widget {
    .t-pager {
        float:none;
        display:flex;
        justify-content: center;
        align-items: center;
        span {
            vertical-align: middle;
        }
        .t-link,
        .t-state-active {
            padding:2px 10px;
            line-height:unset;
            margin:0;
        }
        .t-state-active {
            background-color:$secondary-color;
            border:1px solid transparent;
            color:$base-bg-color;
        }
        .t-link {
            &:hover,
            &:focus {
                background-color:$secondary-color;
                border:none;
                border-radius:5px;

            }
        }
    }
}

.shop {
    #news-rotator-1, 
    #news-rotator-2,
    #news-rotator-3,
    #main-selection {
        padding:50px 0;

        .section-title {
            text-align: center;
            margin-bottom:1.5em;
        }
        .more-events {
            text-align: center;
            text-decoration: underline;
            margin-top:2em;
        }

        .bigCarousel {
            .carousel-book {
                min-height:280px;
            }
        }
    }
}

@media screen and (max-width:1000px) {
    #results-page {
        margin-top:30px;
    }
    .search-form,
    .results-list {
        width:100%;
        margin:0;
    }

    .results-list {
        margin-top:30px;
    }

    .results-list {
        #ResultsGrid {
            tbody {
                tr {
                    td {
                        padding:25px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:580px) {
    .advancedSearch {
        form {
            .advanced-flex {
                > div {
                    width:100%;
                }
            }
            input[type=submit] {
                margin:2em auto 0;
                display:block;
            }

        }
    }
}

@media screen and (max-width:480px) {
    .results-list {
        #ResultsGrid {
            tbody {
                .cartouche {
                    flex-wrap: wrap;
                    justify-content: center;
                    .imgCartouche {
                        width:50%;
                    }
                    .texteCartouche {
                        width:100%;
                        text-align: center;
                        table {
                            display: none;
                        }
                        .btnAdd {
                            margin:0 auto;
                        }
                    }
                }
            }
        }
        .titreBook {
            font-size:1.2em;
        }
    }

    .detailContent {
        .section {
            padding-bottom:0px;
        }
        .flex {
            flex-direction: column;
            align-items: center;
            .bookCover {
                order:-1;
                width:50%;
            }
            .titleZone {
                text-align: center;
                width:100%;
                .addBasketContainer {
                    justify-content: center;
                }
            }
        }
    }
}
