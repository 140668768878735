@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-20
 * @note           	Mes commandes
 */

.date-filter {
	margin: 1em 0;

	form > div {
		@media screen and ( max-width: 420px ) {
			margin: .5em 0;
		}

		@media screen and ( min-width: 421px ) {
			display: inline-block;
		}
	}
}

#OrdersGrid {
	dt {
		font-weight: 500;
	}

	dd {
		margin-left: 0;
	}

	@media screen and ( max-width: 450px ) {
		dd {
			margin-bottom: 1em;
		}
	}

	@media screen and ( min-width: 451px ) {
		dt,
		dd {
			display: inline-block;
			vertical-align: top;
		}

		dt {
			width: 150px;
		}

		dd {
			width: calc( 100% - 155px );
		}
	}
}

#OrdersGrid {
	> table {
		table-layout: auto;

		> tbody > tr {
			&:first-child > td {
				border-top: 1px solid $fieldset;
			}

			&:not(:last-child) > td {
				border-bottom: 1px solid $fieldset;
			}
		}
	}

	@media screen and ( max-width: $screen-small+px ) {
		border-top: none;

		colgroup,
		thead {
			display: none;
		}
	}
}

[id*="OrderDetails"] {
	border: 1px solid $grey-medium;

	@media screen and ( max-width: $screen-small+px ) {
		border-top: none;

		colgroup,
		thead {
			display: none;
		}

		tbody tr,
		tbody td {
			display: block;
		}

		tbody td {
			padding: .25em .5em;

			&:before {
				content: attr(data-header);
				font-weight: 500;
				display: inline-block;
				margin-right: .25em;
				width: 100px;
			}

			&:first-child {
				font-weight: bold;

				&:before {
					display: none;
				}
			}

			// &:not(:first-child):before {
			// 	display: inline-block;
			// 	margin-right: .25em;
			// 	width: 80px;
			// }
		}
	}
}

#tblBasketTable {
	text-align: right;
	margin: .5em 1em;
	font-weight: 500;
}