@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-12
 * @note            Contact
 */

.contact-form {
	@media screen and ( max-width: 1090px ) {
		> div {
			&:nth-child(2) {
				margin-top: 2em;
				padding-top: 1em;
				border-top: 1px solid $form-border;
			}
		}
	}

	@media screen and ( min-width: 431px ) and ( max-width: 1090px ) {
		> div {
			&:nth-child(2) {
				display: table;
				width: 100%;

				address,
				p {
					display: table-cell;
					vertical-align: bottom;
				}
			}
		}		
	}

	@media screen and ( min-width: 1091px ) {
		display: table;
		width: 100%;

		> div {
			display: table-cell;
			vertical-align: top;

			&:nth-child(1) { width: 70%; }
			&:nth-child(2) { width: 30%; }
		}
	}

	textarea {
		@media screen and ( min-width: 881px ) {
			width: 350px;
		}

		min-height: 100px;
		resize: vertical;
		vertical-align: top;
	}


	@media screen and ( min-width: 881px ) {
		label {
			width: 140px;
		}

		.button-submit {
			margin-left: 140px;
		}

		.no-label {
			padding-left: 143px;
		}
	}

	// ReCaptcha
	.no-label {
		> p {
			@extend .visually-hidden;
		}
	}

	#recaptcha_widget_div {
		width: 450px;
		// max-width: 100%;
	}

	#recaptcha_table {
		border: none !important; // Écrase les styles en ligne

		.recaptcha_image_cell,
		> tbody > tr:nth-child(2) > td {
			padding-left: 0 !important;
		}
	}

	#recaptcha_response_field {
		border: 1px solid $form-border !important; // Écrase les styles en ligne
		background-color: $form-bg !important; // Écrase les styles en ligne

		&:focus {
			border-color: $grey-medium !important; // Écrase les styles en ligne
		}
	}

	.error-message + p + #recaptcha_widget_div {
		#recaptcha_response_field {
			border-color: $error !important; // Écrase les styles en ligne

			&:focus {
				border-color: $grey-medium !important; // Écrase les styles en ligne
			}
		}
	}

	// Adresse
	.name {
		font: {
			family: $font-family-alt;
			size: em(20);
			weight: bold;
		}
		text: {
			transform: uppercase;
		}
	}
}