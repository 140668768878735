@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2017-10-12
 * @note            News
 */

.news-head {
	padding-bottom: .5em;
	margin-bottom: 1em;
	border-bottom: 1px solid $form-border; // Idem back-link
	color: $grey-medium;
}

.view-more {
	margin-top: 1em;

	a:before {
		display: inline-block;
		content: "\203A";
		margin-right: .25em;
		font-size: 1.2em;
		line-height: 1;
	}

	a,
	a:active,
	a:visited {
		color: $fourth-color;
		text-decoration: none;
	}

	a:hover,
	a:focus {
		color: $content-color;
	}
}