@charset "UTF-8";
/**
 * @site			E-commerces Bookshop
 * @author			Luc Poupard
 * @lastmodified	2018-02-01
 * @note            Forms
 */


/* ----------------------------- */
/* Sommaire */
/* ----------------------------- */
/*
	== Champs
	== Boutons
	  -- Bouton principal : submit
	  -- Bouton secondaire : reset
	  -- Bouton ajout au panier
	== Formulaires
	  -- Création de compte
	  -- Mot de passe oublié
*/

fieldset {
	margin: 0;
}

/* == @section Champs ==================== */
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="search"] {
	padding: .25em;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="search"],
select {
	height: 24px;
	border: 1px solid $grey-medium;

	&:focus {
		border-color: $content-color;
	}
}


/* == @section Boutons ==================== */
.form-buttons {
	padding: .625em 0; // Reprise partiel des padding de fieldset dans normalize
}

fieldset + .form-buttons {
	padding: .625em .75em; // Reprise partiel des padding de fieldset dans normalize
}


.form input[type="submit"],
a[class*="button"] {
	display: inline-block;
	padding: .2em 1em;
}


/* -- @subsection Bouton principal : submit ---------- */
.form input[type="submit"],
input[type="submit"].button-submit,
.button-submit {
	background-color: $form-button-bg-color;
	color: $form-button-color;

	&:hover,
	&:focus {
		background-color: $form-button-bg-color-hover;
		color: $form-button-color-hover;
	}
}

/* Reset spécifiques si lien ou bouton */
main a.button-submit {
	text-decoration: none;
}

.form input[type="submit"],
input[type="submit"].button-submit {
	border: none;
}


/* -- @subsection Bouton secondaire : reset ---------- */
main a.button-reset {}


/* -- @subsection Bouton ajout au panier ---------- */
.button-basket {
	background: $gradient-add-top;
	background: -moz-linear-gradient(top, $gradient-add-top 0%, $gradient-add-bottom 100%);
	background: -webkit-linear-gradient(top,  $gradient-add-top 0%,$gradient-add-bottom 100%);
	background: linear-gradient(to bottom,  $gradient-add-top 0%,$gradient-add-bottom 100%);
	color: $white;

	&:hover,
	&:focus {
		background: $gradient-add-top-hover;
		background: -moz-linear-gradient(top, $gradient-add-top-hover 0%, $gradient-add-bottom-hover 100%);
		background: -webkit-linear-gradient(top,  $gradient-add-top-hover 0%,$gradient-add-bottom-hover 100%);
		background: linear-gradient(to bottom,  $gradient-add-top-hover 0%,$gradient-add-bottom-hover 100%);
	}
}

main a.button-basket,
.qtip a.button-basket {
	text-decoration: none;
}


/* == @section Erreurs ==================== */
// On masque .field-validation-error qui est affiché avant le label et le champ
// Ce message permet juste de styler le label et le champ en cas d'erreur
.field-validation-error,
.field-validation-valid {
	display: none;
}

// On affiche .field-validation-error dans .error-message
// Ce message affiche l'erreur
.error-message .field-validation-error,
label .field-validation-error	{
	display: inline-block;
}

// On style les champs, labels et messages en fonction du premier .field-validation-error
.error-message,
.field-validation-error,
.field-validation-error + label,
.field-validation-error + span + label,
.field-validation-error + label + input + .error-message,
.field-validation-error + span + label + input + .error-message,
.field-validation-error + label + select + .error-message,
.field-validation-error + span + label + select + .error-message {
	color: $error;
}

.form {
	.field-validation-error + label,
	.field-validation-error + span + label {
		+ input,
		+ textarea,
		+ select {
			border-color: $error;
		}
	}
}


/* == @section Formulaires ==================== */
.form {
	> div,
	form > div {
		margin: .5em 0;
	}

	fieldset {
		position: relative;
		border: none;

		&:not(:last-of-type) {			
			border-bottom: 1px solid $fieldset;
		}

		> div {
			margin: .5em 0;
		}
	}

	legend {
		color: $grey-light;
		text-transform: uppercase;
	}

	input[type="checkbox"] + label,
	input[type="radio"] + label {
		padding-left: .25em;
		width: auto;
		text-align: left;
	}

	@media screen and ( max-width: 880px ) {
		fieldset {
			padding: .5em 0 1.5em 0;
		}

		legend {
			margin-top: 1em;
		}

		label,
		.label,
		input[type="text"],
		input[type="number"],
		input[type="email"],
		input[type="password"],
		textarea,
		select {
			display: block;
		}

		label,
		.label {
			margin-top: 10px;
		}

		input[type="checkbox"] +label,
		input[type="radio"] +label {
			display: inline-block;
		}

		input[type="text"],
		input[type="number"],
		input[type="email"],
		input[type="password"],
		textarea,
		select {
			width: 100%;
		}

		.form-buttons {
			text-align: center;
		}
	}

	@media screen and ( min-width: 881px ) {
		legend {
			position: absolute;
			top: .5em;
			left: 0;
			max-width: 100px;
		}

		label,
		.label {
			display: inline-block;
			padding-right: 10px;
			width: $label-width+px;
			text-align: right;
		}

		input[type="checkbox"] +label,
		input[type="radio"] +label {
			width: auto;
			text-align: left;
		}

		.no-label {
			padding-left: $label-width+px;
		}

		input[type="text"],
		input[type="number"],
		input[type="email"],
		input[type="password"],
		input[type="search"],
		select {
			width: $input-width+px;
		}

		.t-datepicker .t-input {
			width: 100%;
		}

		input[type="submit"],
		.button-submit {
			margin-left: $label-width+px;
		}
	}

	@media screen and ( min-width: 1121px ) {
		.error-message {
			display: inline-block;
			vertical-align: top;
		}
	}
}

main,
.windowLogin {
	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="password"],
	textarea,
	select {
		border: 1px solid $form-border;
		background-color: $form-bg;

		&:focus {
			border-color: $grey-medium;
		}
	}
}

/* -- @subsection Création de compte ---------- */
.register-form {
	@media screen and ( min-width: 881px ) and ( max-width: 1120px ) {
		.error-message {
			display: block;
			margin-left: $label-width+px;
		}
	}

	@media screen and ( min-width: 1121px ) {
		.error-message {
			width: calc( 100% - 560px);
		}
	}
}

/* -- @subsection Mot de passe oublié ---------- */
.password-form {
	label {
		width: 80px;
	}

	@media screen and ( min-width: 881px ) {
		.button-submit {
			margin-left: 70px;
		}
	}

	@media screen and ( min-width: 1121px ) {
		.error-message {
			width: calc( 100% - 340px);
		}
	}
}